import React from 'react';
import clsx from 'clsx';
import { PageTitle } from '@oysterjs/ui/Page/section';
import { Button as HeadlessButton } from '@headlessui/react';
import { ArrowRightIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label
} from '@oysterjs/partners/uiv2/fieldset';
import { Button } from '@oysterjs/partners/uiv2/button';
import { Textarea } from '@oysterjs/partners/uiv2/textarea';
import { Select } from '@oysterjs/partners/uiv2/select';
import {
  BusinessPolicyFieldsFragment,
  PolicyConversationMessage,
  PolicyConversationMessageRole,
  PolicyState,
  useAddPolicyConversationMessageMutation,
  useGetPolicyConversationQuery
} from '../../types/graphql';
import { getDisplayInsuranceType } from '../../types/map';

export const ExploreRiskPage = (props: { businessPolicies: BusinessPolicyFieldsFragment[] }) => {
  const explorablePolicies = getExplorablePolicies(props.businessPolicies);
  if (!explorablePolicies.length) {
    return null;
  }

  const [selectedPolicy, setSelectedPolicy] = React.useState<BusinessPolicyFieldsFragment | null>(
    explorablePolicies[0] || null
  );
  const [showPolicyQuotes, setShowPolicyQuotes] = React.useState<Record<string, boolean>>({});

  if (!selectedPolicy) {
    return (
      <div className="text-center">
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="mx-auto h-12 w-12 text-neutral-400"
        >
          <path
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            strokeWidth={2}
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-neutral-900">No policies</h3>
        <p className="mt-1 text-sm text-neutral-500">
          You don't have any policies to explore. Get started by applying for one.
        </p>
        <div className="mt-6 flex gap-2 items-center justify-center">
          <Button type="button" color="white">
            Go back
          </Button>
          <Button type="button" color="sky">
            Apply for a policy
          </Button>
        </div>
      </div>
    );
  }

  const policyStats = [
    {
      name: 'Premium',
      value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        selectedPolicy.pricing.premium || 0
      )
    },
    // {
    //   name: 'Deductible',
    //   value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    //     selectedPolicy.details?.deductible || 0
    //   )
    // },
    {
      name: 'Policy Number',
      value: selectedPolicy.policyNumber || selectedPolicy.details?.policyNumber || 'N/A'
    },
    {
      name: 'Expiration',
      value: new Date(selectedPolicy.expiresAt || '').toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      })
    }
  ];

  const policyLimits = [
    { name: 'aggregate', limit: selectedPolicy.details?.limits?.cyberAggregateLimit },
    { name: 'retention', limit: selectedPolicy.details?.limits?.cyberRetentionLimit },
    { name: 'aggregate', limit: selectedPolicy.details?.limits?.glAggregateLimit },
    {
      name: 'medical per occurrence',
      limit: selectedPolicy.details?.limits?.glMedicalPerOccurrenceLimit
    },
    {
      name: 'medical per person',
      limit: selectedPolicy.details?.limits?.glMedicalPerPersonLimit
    },
    { name: 'per occurrence', limit: selectedPolicy.details?.limits?.glPerOccurrenceLimit },
    {
      name: 'personal and advertising injury',
      limit: selectedPolicy.details?.limits?.glPersonalAndAdvertisingInjuryLimit
    },
    {
      name: 'products and completed operations',
      limit: selectedPolicy.details?.limits?.glProductsAndCompletedOperationsLimit
    },
    {
      name: 'rented premises damage',
      limit: selectedPolicy.details?.limits?.glRentedPremisesDamageLimit
    },
    { name: 'building', limit: selectedPolicy.details?.limits?.propertyBuildingLimit },
    { name: 'contents', limit: selectedPolicy.details?.limits?.propertyContentsLimit },
    { name: 'per accident', limit: selectedPolicy.details?.limits?.wcPerAccidentLimit },
    {
      name: 'per disease (employee)',
      limit: selectedPolicy.details?.limits?.wcPerDiseaseEmployeeLimit
    },
    {
      name: 'per disease (policy)',
      limit: selectedPolicy.details?.limits?.wcPerDiseasePolicyLimit
    },
    ...(selectedPolicy.details?.extraLimits || []),
    ...(selectedPolicy.details?.locations?.flatMap((location) => [
      {
        name: `building (${location.address.line1})`,
        limit: location.limits?.propertyBuildingLimit
      },
      {
        name: `contents (${location.address.line1})`,
        limit: location.limits?.propertyContentsLimit
      }
    ]) || [])
  ].filter((limit) => !!limit?.limit);

  return (
    <>
      <PageTitle
        title="Explore your coverage"
        description="Explore your policies and understand what you're covered for. Make sure you speak with an agent before acting on any information here."
      />
      <form className="sm:max-w-md" onSubmit={(e) => e.preventDefault()}>
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>Choose a policy</Label>
              <Description>Select the policy you want to explore.</Description>
              <Select
                value={selectedPolicy.id}
                onChange={(e) => {
                  setSelectedPolicy(
                    explorablePolicies.find((p) => p.id === e.currentTarget.value) || null
                  );
                }}
              >
                {explorablePolicies.map((policy) => (
                  <option key={policy.id} value={policy.id}>
                    {getDisplayInsuranceType(policy.type)} (
                    {policy.policyNumber || policy.details?.policyNumber || 'N/A'})
                  </option>
                ))}
              </Select>
            </Field>
          </FieldGroup>
        </Fieldset>
      </form>
      <div className="mt-8 w-full grow xl:flex">
        <div className="flex-1 space-y-8 pb-8 w-full xl:pb-0 xl:pr-4 xl:flex-1">
          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Policy overview
                  </h3>
                  <p className="text-sm text-neutral-500">Basic details about your policy.</p>
                </div>
              </div>
            </div>
            <div className="@container">
              <dl className="px-4 py-5 sm:px-6 mx-auto grid grid-cols-1 gap-8 @sm:grid-cols-2 @lg:grid-cols-3">
                {policyStats.map((stat) => (
                  <div
                    key={stat.name}
                    className="flex flex-wrap items-baseline justify-between bg-white"
                  >
                    <dt className="text-sm font-medium text-neutral-500">{stat.name}</dt>
                    <dd className="w-full flex-none text-xl font-medium leading-10 tracking-tight text-neutral-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
              <dl className="divide-y divide-neutral-100 border-t border-neutral-100 pb-2">
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Insured Entity</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    <span className="font-medium">
                      {selectedPolicy.details?.insured?.name || 'Unknown'}
                    </span>
                    <br />
                    <span>{selectedPolicy.details?.insured?.address?.line1 || 'Unknown'}</span>
                    <br />
                    <span>
                      {selectedPolicy.details?.insured?.address?.city || 'Unknown'},{' '}
                      {selectedPolicy.details?.insured?.address?.zone || 'Unknown'}{' '}
                      {selectedPolicy.details?.insured?.address?.postalCode || 'Unknown'}
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Carrier</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    <span className="font-medium">
                      {selectedPolicy.details?.carrier?.name || 'Unknown'}
                    </span>
                    <br />
                    <span>{selectedPolicy.details?.carrier?.address?.line1 || 'Unknown'}</span>
                    <br />
                    <span>
                      {selectedPolicy.details?.carrier?.address?.city || 'Unknown'},{' '}
                      {selectedPolicy.details?.carrier?.address?.zone || 'Unknown'}{' '}
                      {selectedPolicy.details?.carrier?.address?.postalCode || 'Unknown'}
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Policy Summary</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    {selectedPolicy.details?.summary || 'Unknown'}
                  </dd>
                </div>
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Limits</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    {policyLimits.map((limit) => (
                      <div key={limit.name}>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          currency: 'USD'
                        }).format(limit.limit || 0)}{' '}
                        {limit.name}
                      </div>
                    ))}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    What's covered
                  </h3>
                  <p className="text-sm text-neutral-500">Coverage for your business.</p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-col gap-2">
                <dl className="grid grid-cols-1 gap-4">
                  {selectedPolicy.details?.coverageDescriptions?.map((coverage) => (
                    <div key={coverage.title}>
                      <dt className="text-sm flex items-center gap-2">
                        <CheckCircleIcon className="h-4 w-4 text-emerald-500" aria-hidden="true" />
                        <span className="font-medium text-neutral-500">{coverage.title}</span>
                      </dt>
                      <dd className="text-sm text-neutral-900 ml-6 mt-1 leading-5">
                        {coverage.description}
                      </dd>
                      {coverage.policyQuotes.length > 0 && !showPolicyQuotes[coverage.title] && (
                        <HeadlessButton
                          onClick={() =>
                            setShowPolicyQuotes({
                              ...showPolicyQuotes,
                              [coverage.title]: true
                            })
                          }
                          className="text-xs font-medium text-primary-500 hover:text-primary-800 inline-flex items-center gap-1 ml-6"
                        >
                          Show relevant quotes <ArrowRightIcon className="h-3 w-3" />
                        </HeadlessButton>
                      )}
                      {coverage.policyQuotes.length > 0 && showPolicyQuotes[coverage.title] && (
                        <div className="flex flex-col gap-2 mt-2 ml-6">
                          {coverage.policyQuotes.map((quote) => (
                            <div
                              key={quote}
                              className="text-xs text-neutral-500 border-l-4 border-neutral-200 px-2 py-0.5"
                            >
                              {quote}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </dl>
                <p className="text-xs text-neutral-400 mt-2">
                  The coverage descriptions provided here may not be complete, and may have
                  important exclusions or limitations. For a full understanding of your coverage,
                  please read your policy documents or speak with an insurance agent.
                </p>
              </div>
            </div>
          </div>

          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    What's not covered
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Exclusions and other limitations on your policy.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-col gap-2">
                <dl className="grid grid-cols-1 gap-4">
                  {selectedPolicy.details?.exclusionDescriptions?.map((exclusion) => (
                    <div key={exclusion.title}>
                      <dt className="text-sm flex items-center gap-2">
                        <XCircleIcon className="h-4 w-4 text-red-500" aria-hidden="true" />
                        <span className="font-medium text-neutral-500">{exclusion.title}</span>
                      </dt>
                      <dd className="text-sm text-neutral-900 ml-6 mt-1 leading-5">
                        {exclusion.description}
                      </dd>
                      {exclusion.policyQuotes.length > 0 && !showPolicyQuotes[exclusion.title] && (
                        <HeadlessButton
                          onClick={() =>
                            setShowPolicyQuotes({
                              ...showPolicyQuotes,
                              [exclusion.title]: true
                            })
                          }
                          className="text-xs font-medium text-primary-500 hover:text-primary-800 inline-flex items-center gap-1 ml-6"
                        >
                          Show relevant quotes <ArrowRightIcon className="h-3 w-3" />
                        </HeadlessButton>
                      )}
                      {exclusion.policyQuotes.length > 0 && showPolicyQuotes[exclusion.title] && (
                        <div className="flex flex-col gap-2 mt-2 ml-6">
                          {exclusion.policyQuotes.map((quote) => (
                            <div
                              key={quote}
                              className="text-xs text-neutral-500 border-l-4 border-neutral-200 px-2 py-0.5"
                            >
                              {quote}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </dl>
                <p className="text-xs text-neutral-400 mt-2">
                  The exclusions provided here may not be complete, and may have important caveats
                  or conditions. For a full understanding of your policy, please read your policy
                  documents or speak with an insurance agent.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="shrink-0 border-neutral-200 w-full space-y-8 xl:pl-4 xl:w-[25rem]">
          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Ask a question
                  </h3>
                  <p className="text-sm text-neutral-500">
                    We'll find related information in your policy.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <PolicyChat policyId={selectedPolicy.id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PolicyChat = (props: { policyId: string }) => {
  // Load the initial conversation messages
  const {
    data: initialData,
    loading: initialLoading,
    refetch: refetchInitialData
  } = useGetPolicyConversationQuery({
    variables: { policyId: props.policyId }
  });

  // Send a message to the policy chat
  const [
    sendMessage,
    { data: sendMessageData, loading: sendMessageLoading, error: sendMessageError }
  ] = useAddPolicyConversationMessageMutation();

  // State for the chat
  const [editorText, setEditorText] = React.useState('');
  const [messages, setMessages] = React.useState<PolicyConversationMessage[]>([]);
  const [stagedMessage, setStagedMessage] = React.useState('');
  const [showAttachments, setShowAttachments] = React.useState<Record<string, boolean>>({});

  React.useEffect(() => {
    refetchInitialData({ policyId: props.policyId });
  }, [props.policyId]);

  React.useEffect(() => {
    if (initialData) {
      setMessages(initialData.policyConversation);
    }
  }, [initialData]);

  React.useEffect(() => {
    if (sendMessageData) {
      setMessages(sendMessageData.riskManagementAddPolicyConversationMessage);
    }
  }, [sendMessageData?.riskManagementAddPolicyConversationMessage]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEditorText('');
    setStagedMessage(editorText);
    await sendMessage({
      variables: { policyId: props.policyId, message: editorText }
    });
    setStagedMessage('');
  };

  if (initialLoading) {
    return (
      <div className="space-y-4 animate-pulse">
        <div className="flex justify-start">
          <div className="rounded-lg p-3 w-full max-w-[80%] bg-neutral-100">
            <div className="h-3 w-3/4 bg-neutral-300 rounded-full" />
            <div className="h-3 w-1/2 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-5/6 bg-neutral-300 rounded-full mt-2" />
          </div>
        </div>
        <div className="flex justify-end">
          <div className="rounded-lg p-3 w-full max-w-[75%] bg-primary-100">
            <div className="h-3 w-3/4 bg-primary-300 rounded-full" />
            <div className="h-3 w-1/2 bg-primary-300 rounded-full mt-2" />
          </div>
        </div>
        <div className="flex justify-start">
          <div className="rounded-lg p-3 w-full max-w-[80%] bg-neutral-100">
            <div className="h-3 w-3/4 bg-neutral-300 rounded-full" />
            <div className="h-3 w-2/3 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-5/6 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-1/2 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-3/4 bg-neutral-300 rounded-full mt-2" />
          </div>
        </div>
        <div className="flex">
          <div className="rounded-lg w-full h-32 border border-neutral-300" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-5">
      <div className="flex-1 overflow-y-auto">
        <div className="space-y-4">
          <div className="flex justify-start">
            <div className="rounded-lg p-3 bg-neutral-100 text-neutral-900 max-w-[75%] xl:max-w-full">
              <pre className="font-body text-sm whitespace-pre-wrap">
                Hello! I'm here to look up information in your policy documents. What would you like
                to know?
              </pre>
            </div>
          </div>
          {messages.map((message) => (
            <div
              key={message.id}
              className={clsx(
                'flex',
                message.role === PolicyConversationMessageRole.User
                  ? 'justify-end'
                  : 'justify-start'
              )}
            >
              <div
                className={clsx(
                  'rounded-lg p-3 flex flex-col max-w-[75%] xl:max-w-full',
                  message.role === PolicyConversationMessageRole.User
                    ? 'bg-sky-500 text-white'
                    : 'bg-neutral-100 text-neutral-900'
                )}
              >
                <pre className="font-body text-sm whitespace-pre-wrap">{message.content}</pre>
                {message.attachments.length > 0 && !showAttachments[message.id] && (
                  <HeadlessButton
                    onClick={() =>
                      setShowAttachments({
                        ...showAttachments,
                        [message.id]: true
                      })
                    }
                    className="text-xs font-medium text-primary-500 hover:text-primary-800 inline-flex items-center gap-1 mt-2"
                  >
                    Show relevant quotes <ArrowRightIcon className="h-3 w-3" />
                  </HeadlessButton>
                )}
                {message.attachments.length > 0 && showAttachments[message.id] && (
                  <div className="flex flex-col gap-2 mt-4">
                    {message.attachments?.map((attachment) => (
                      <div
                        key={attachment.content}
                        className="text-xs border-l-4 border-neutral-200 px-2 py-0.5"
                      >
                        {attachment.content}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
          {sendMessageLoading && (
            <>
              <div className="flex justify-end">
                <div className="rounded-lg p-3 bg-sky-500 text-white">
                  <pre className="font-body text-sm whitespace-pre-wrap">{stagedMessage}</pre>
                </div>
              </div>
              <AIChatProgress />
            </>
          )}
          <div className="mt-4 text-center">
            <p className="text-xs text-neutral-400">
              Please note: This assistant will attempt to find information in your policy. However,
              it is not a licensed agent. Always check your policy documents or schedule a call with
              an Oyster agent for the most accurate information.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <form onSubmit={handleSubmit} className="relative">
          <Fieldset>
            <FieldGroup>
              <Field>
                <Label className="sr-only">Ask a question or followup</Label>
                <Textarea
                  rows={4}
                  placeholder="Ask a question or followup..."
                  resizable={false}
                  disabled={sendMessageLoading}
                  value={editorText}
                  onChange={(e) => setEditorText(e.target.value)}
                />
                {sendMessageError && <ErrorMessage>{sendMessageError.message}</ErrorMessage>}
              </Field>
            </FieldGroup>
          </Fieldset>

          <Button
            type="submit"
            color="sky"
            className="mt-4 justify-end"
            disabled={sendMessageLoading}
          >
            Send Message
          </Button>
        </form>
      </div>
    </div>
  );
};

const AIChatProgress = () => {
  // Build a two-minute animation to keep the user engaged while the quote loads
  const steps = [
    { title: 'Loading policy documents...', maxDuration: 2000, maxWidth: 10 },
    { title: 'Reading carefully...', maxDuration: 6000, maxWidth: 45 },
    { title: 'Thinking...', maxDuration: 5000, maxWidth: 80 },
    { title: 'Thinking...', maxDuration: 8000, maxWidth: 90 },
    { title: 'Thinking...', maxDuration: 13000, maxWidth: 100 }
  ];

  const [stepIndex, setStepIndex] = React.useState(0);
  React.useEffect(() => {
    if (stepIndex === steps.length) {
      return;
    }

    const timeout = setTimeout(() => {
      setStepIndex((prev) => (prev >= steps.length - 1 ? prev : prev + 1));
    }, steps[stepIndex].maxDuration);

    return () => clearTimeout(timeout);
  }, [stepIndex]);

  return (
    <div className="flex justify-start">
      <div className="rounded-lg p-3 w-full max-w-[80%] bg-neutral-100">
        <div className="h-3 w-3/4 bg-neutral-300 rounded-full animate-pulse" />
        <div className="h-3 w-2/3 bg-neutral-300 rounded-full mt-2 animate-pulse" />
        <div className="h-3 w-5/6 bg-neutral-300 rounded-full mt-2 animate-pulse" />

        <div className="mt-4 text-xs font-medium text-primary-600 dark:text-primary-400">
          {steps[stepIndex].title}
        </div>
        <div className="mt-1">
          <div className="h-2 w-full rounded-full bg-neutral-100 dark:bg-neutral-700">
            <div
              className={`h-2 rounded-full bg-gradient-to-r to-primary-300 from-primary-600 bg-[size:200%_100%] animate-progress-bar transition-all ease-in-out duration-1000`}
              style={{ width: `${steps[stepIndex].maxWidth}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getExplorablePolicies = (policies: BusinessPolicyFieldsFragment[]) =>
  policies.filter(
    (policy) => policy.state === PolicyState.Inforce && !!policy.details?.carrier?.address.line1
  );
