import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Merchant, MerchantUser, ValidationError } from '@oysterjs/types';
import { getMerchantAccount } from '@oysterjs/core/api/merchant';
import { PageContainer, PageSection } from './components/Page';
import { Spinner } from '@oysterjs/ui/Spinner/index';

export const ThrowError = (props: { error: Error }) => {
  throw props.error;
};

export const Authenticated = (props: React.PropsWithChildren<unknown>) => {
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState<{
    Merchant: Merchant;
    MerchantUser: MerchantUser;
    PersonalizationError?: ValidationError;
  }>();

  const history = useHistory();
  const match = useRouteMatch();

  React.useEffect(() => {
    if (!loaded) {
      getMerchantAccount()
        .then((data) => setData(data))
        .finally(() => setLoaded(true));
    }
  }, [loaded]);

  React.useEffect(() => {
    setLoaded(false);
  }, [match.url]);

  if (!loaded) {
    return (
      <PageContainer width={600}>
        <PageSection centered>
          <Spinner color="#333333" />
        </PageSection>
      </PageContainer>
    );
  }

  if (data?.PersonalizationError) {
    // launch personalization flow
    history.replace('/personalization');
    return null;
  }

  if (data?.Merchant) {
    return <>{props.children}</>;
  }

  return null;
};
