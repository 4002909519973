import ReactTooltip from 'react-tooltip';
import { Button } from '@oysterjs/partners/uiv2/button';
import styled from 'styled-components';

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px;
`;

const LinkDisplay = styled.div`
  border: 0;

  display: flex;
  flex-direction: row;

  padding: 10px 15px;
  border-radius: 4px;
  box-sizing: border-box;

  font-size: 0.8em;
  font-weight: 500;

  min-width: 100px;
  width: 100%;
  justify-content: start;

  color: #000000;
  background: #f2f2f2;
  border: '1px solid #DADADA';
  font-family: 'Inter', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
`;

export const CopyableLink = (props: { link: string }) => (
  <LinkContainer>
    <a
      data-tip
      data-for="copy-text"
      data-event="click"
      data-event-off="mouseout"
      style={{ flex: '1' }}
    >
      <LinkDisplay>{props.link}</LinkDisplay>
    </a>
    <ReactTooltip
      id="copy-text"
      effect="solid"
      delayHide={1000}
      afterShow={() => navigator.clipboard.writeText(props.link)}
    >
      Copied!
    </ReactTooltip>
    <a data-tip data-for="copy-button" data-event="click" data-event-off="mouseout">
      <Button type="button" color="sky" onClick={() => navigator.clipboard.writeText(props.link)}>
        Copy your link
      </Button>
    </a>
    <ReactTooltip
      id="copy-button"
      effect="solid"
      delayHide={1000}
      afterShow={() => navigator.clipboard.writeText(props.link)}
    >
      Copied!
    </ReactTooltip>
  </LinkContainer>
);
