import * as React from 'react';
import clsx from 'clsx';

import {
  Merchant,
  MerchantIntegration,
  MerchantIntegrationStatus,
  MerchantIntegrationType,
  MerchantRentalConfiguration,
  RentalPolicyState
} from '@oysterjs/types';
import { useHistory, useLocation } from 'react-router';
import { Slideout } from '@oysterjs/partners/uiv2/slideout';
import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import { ErrorBoundaryV2 } from '@oysterjs/ui/ErrorBoundary';
import { MerchantRentalSetup } from '../../setups/rental';

const rentalInsuranceChannels: MerchantIntegrationType[] = [MerchantIntegrationType.checkfront];

const getChannelLabel = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.checkfront:
      return 'Checkfront';
    default:
      return '';
  }
};

const getChannelDescription = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.checkfront:
      return 'Configure the Oyster CheckFront app to offer customers peace-of-mind.';
    default:
      return '';
  }
};

const getChannelImage = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.checkfront:
      return <img className="h-8 w-8 flex-shrink-0" src="/images/checkfront.svg" />;
    default:
      return <></>;
  }
};

const getChannelPath = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.checkfront:
      return '/integrations/channels/checkfront';
    default:
      return '/integrations/channels';
  }
};

const getBadgeColor = (status: MerchantIntegrationStatus): string => {
  switch (status) {
    case MerchantIntegrationStatus.active:
      return 'bg-green-50 text-green-700 ring-green-600/20';
    case MerchantIntegrationStatus.pending:
      return 'bg-amber-50 text-amber-700 ring-amber-600/20';
    case MerchantIntegrationStatus.disabled:
      return 'bg-red-50 text-red-700 ring-red-600/20';
    case MerchantIntegrationStatus.inactive:
      return 'bg-neutral-50 text-neutral-700 ring-neutral-600/20';
  }
};

const getRentalConfigurationStatus = (configuration?: MerchantRentalConfiguration) => {
  switch (configuration?.Details.State) {
    case RentalPolicyState.submitted:
    case RentalPolicyState.registered:
      return MerchantIntegrationStatus.pending;
    case RentalPolicyState.approved:
    case RentalPolicyState.approved_blanket:
      return MerchantIntegrationStatus.active;
  }
};

const ChannelBadge = (props: { status?: MerchantIntegrationStatus }) => (
  <span
    className={clsx(
      'inline-flex flex-shrink-0 rounded-full px-2.5 py-0.5 text-xs font-medium ring-1 ring-inset m-0',
      getBadgeColor(props.status || MerchantIntegrationStatus.inactive)
    )}
  >
    {props.status || 'Not added'}
  </span>
);

export const ChannelCard = (props: {
  type: MerchantIntegrationType;
  status?: MerchantIntegrationStatus;
}) => {
  const history = useHistory();
  return (
    <Card
      label={getChannelLabel(props.type)}
      status={props.status}
      description={getChannelDescription(props.type)}
      image={getChannelImage(props.type)}
      onClick={() => history.push(getChannelPath(props.type))}
    />
  );
};

const Card = (props: {
  label: string;
  status?: MerchantIntegrationStatus;
  description: string;
  image: React.JSX.Element;
  onClick?: () => void;
}) => (
  <li
    role="button"
    className="col-span-1 rounded-lg bg-white shadow transition-all duration-200 hover:shadow-lg cursor-pointer"
    onClick={props.onClick}
  >
    <div className="flex w-full justify-between space-x-6 p-6">
      <div className="flex-1">
        <div className="flex items-start gap-x-3 flex-row">
          <h3 className="text-sm my-0 font-medium text-neutral-900">{props.label}</h3>
          <ChannelBadge status={props.status} />
        </div>
        <p className="text-sm mt-1 text-neutral-500">{props.description}</p>
      </div>
      {props.image}
    </div>
  </li>
);

interface ChannelSlideoutContentProps {
  title: string;
  description: string;
  integrationStatus?: MerchantIntegrationStatus;
}

const ChannelSlideoutContent = (props: React.PropsWithChildren<ChannelSlideoutContentProps>) => (
  <>
    <div className="flex flex-row justify-between items-center">
      <h2 className="text-xl font-semibold mt-0 text-neutral-900">{props.title}</h2>
      <ChannelBadge status={props.integrationStatus} />
    </div>
    <p className="text-sm text-neutral-500">{props.description}</p>
    <div className="mt-4">{props.children}</div>
  </>
);

export const ChannelsListPage = (props: {
  apiKey: string;
  merchant: Merchant;
  merchantIntegrations: MerchantIntegration[];
  rentalConfiguration?: MerchantRentalConfiguration;
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
}) => {
  const history = useHistory();
  const location = useLocation();

  const onClickRental = () => {
    if (props.rentalConfiguration?.Details.State === RentalPolicyState.approved) {
      history.push('/rental');
    }

    history.push('/integrations/channels/rental');
  };

  return (
    <div className="@container">
      <div className="border-b border-neutral-200 pb-4">
        <h2 className="text-base font-medium text-neutral-900 font-body m-0">Rental Insurance</h2>
        <p className="mt-1 text-sm text-neutral-500">
          Configure the channels you use and offer rental waivers to your customers through them.
        </p>
      </div>
      <ul role="list" className="grid grid-cols-1 gap-8 mt-8 @lg:grid-cols-2 @5xl:grid-cols-3">
        <Card
          key="rental_insurance"
          label="Rental Insurance"
          status={getRentalConfigurationStatus(props.rentalConfiguration)}
          description="Provide theft and damage waivers to customers for rentals."
          image={<DocumentCheckIcon className="h-8 w-8 flex-shrink-0 text-neutral-700" />}
          onClick={onClickRental}
        />
        {props.merchantIntegrations.map(
          (integration) =>
            rentalInsuranceChannels.includes(integration.Type) && (
              <ChannelCard
                key={integration.ID}
                type={integration.Type}
                status={integration.Status}
              />
            )
        )}
        {rentalInsuranceChannels
          .filter((integration) => {
            return props.merchantIntegrations.every(
              (merchantIntegration) => merchantIntegration.Type != integration
            );
          })
          .map((integration) => (
            <ChannelCard key={integration} type={integration} />
          ))}
      </ul>
      <Slideout
        title="View app"
        showing={location.pathname.endsWith('/rental')}
        onClose={() => history.replace('/integrations/channels')}
      >
        <ChannelSlideoutContent
          title="Rental Insurance"
          description="Provide theft and damage waivers to customers for rentals"
          integrationStatus={getRentalConfigurationStatus(props.rentalConfiguration)}
        >
          <ErrorBoundaryV2>
            <MerchantRentalSetup
              merchant={props.merchant}
              configuration={props.rentalConfiguration}
              onContinueToRental={() => history.replace('/rental')}
            />
          </ErrorBoundaryV2>
        </ChannelSlideoutContent>
      </Slideout>
    </div>
  );
};
