import * as React from 'react';

import { Select } from '@oysterjs/partners/uiv2/select';
import { getMerchantReferrals, getMerchantTransactions } from '@oysterjs/core/api/merchant';
import { Loadable } from '@oysterjs/ui/Loadable';
import { PolicyTableValues, TransactionType } from '@oysterjs/types';
import { Table } from '@oysterjs/ui/Table';
import { Description, Field, FieldGroup, Fieldset, Label } from '@oysterjs/partners/uiv2/fieldset';

const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const ReferralItem = (props: { referral: PolicyTableValues }) => {
  const item = props.referral.InsuredItems[0];
  return (
    <tr>
      <td>{new Intl.DateTimeFormat('en-US').format(new Date(props.referral.CreatedAt))}</td>
      <td>{item.Name}</td>
      <td>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'usd',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(item.Price.Amount)}
      </td>
    </tr>
  );
};

const AsyncMerchantReferrals = (props: { startDate: Date; endDate: Date }) => (
  <Loadable
    request={Promise.all([
      getMerchantReferrals(props.startDate, props.endDate),
      getMerchantTransactions(props.startDate, props.endDate)
    ])}
  >
    {([referrals, transactions]) => {
      const payouts = transactions.Transactions?.filter((t) => t.Type === TransactionType.credit);
      if (referrals.Referrals.length == 0)
        return (
          <div className="text-center mt-8">
            <svg
              className="mx-auto h-12 w-12 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <ellipse cx="12" cy="5" rx="8" ry="2" strokeWidth="1" />
              <path d="M4 5v3c0 1.1 3.6 2 8 2s8-.9 8-2V5" strokeWidth="1" />
              <path d="M4 8v3c0 1.1 3.6 2 8 2s8-.9 8-2V8" strokeWidth="1" />
              <path d="M4 11v3c0 1.1 3.6 2 8 2s8-.9 8-2v-3" strokeWidth="1" />
              <line x1="2" y1="2" x2="22" y2="18" stroke="currentColor" strokeWidth="1" />
            </svg>
            <h3 className="mt-1 text-sm font-semibold text-neutral-900">No data</h3>
            <p className="mt-1 text-sm text-neutral-500">
              There were no referrals eligible for payout in this month.
            </p>
          </div>
        );
      else
        return (
          <>
            <Table style={{ marginTop: '24px' }}>
              <thead>
                <tr>
                  <td>Date</td>
                  <td>Item</td>
                  <td>Value</td>
                </tr>
              </thead>
              <tbody>
                {referrals.Referrals.map((referral) => (
                  <ReferralItem referral={referral} />
                ))}
              </tbody>
            </Table>
            {payouts && payouts.length > 0 && (
              <Table style={{ marginTop: '24px' }}>
                <thead>
                  <tr>
                    <td>Date</td>
                    <td>Transaction ID</td>
                    <td>Amount</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {payouts.map((payout) => (
                    <tr>
                      <td>{new Date(payout.CreatedAt).toLocaleDateString()}</td>
                      <td>{payout.ProcessorID || 'TBD'}</td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: payout.Currency,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(payout.Amount)}
                      </td>
                      <td>{payout.State}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </>
        );
    }}
  </Loadable>
);
const getMonthsDate = (today: Date) =>
  [0, 1, 2, 3, 4, 5, 6].map((diff) => ({
    start: Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - diff, 1, 0, 0, 0, 0),
    end: Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1 - diff, 1, 0, 0, 0, 0)
  }));

const getMonthName = (date: Date): string => monthList[date.getUTCMonth()];

export const ReferralsPage = () => {
  const monthOptions = getMonthsDate(new Date()).map(({ start, end }, i) => ({
    index: i.toString(),
    title: `${getMonthName(new Date(start))} ${new Date(start).getUTCFullYear()}`,
    start,
    end
  }));

  const [month, setMonth] = React.useState(monthOptions[0]);

  return (
    <>
      <form className="sm:max-w-md" onSubmit={(e) => e.preventDefault()}>
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label className="sm:text-base">Referrals and Payouts</Label>
              <Description>Select the month of data you want to explore.</Description>
              <Select
                value={month.index}
                onChange={(e) => {
                  setMonth(monthOptions[parseInt(e.currentTarget.value)]);
                }}
              >
                {monthOptions.map((opt) => (
                  <option key={opt.index} value={opt.index}>
                    {opt.title}
                  </option>
                ))}
              </Select>
            </Field>
          </FieldGroup>
        </Fieldset>
      </form>
      <AsyncMerchantReferrals startDate={new Date(month.start)} endDate={new Date(month.end)} />
    </>
  );
};
