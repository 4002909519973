import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Button as HeadlessButton } from '@headlessui/react';

import { ShieldCheckIcon, LockClosedIcon, ComputerDesktopIcon } from '@heroicons/react/20/solid';
import { OysterWordMark } from '@oysterjs/ui/Logo';
import { Field, FieldGroup, Fieldset, Label } from '@oysterjs/partners/uiv2/fieldset';
import {
  CreateBusinessApplicationDocument,
  CreateBusinessApplicationMutation
} from '../../types/graphql';
import { Input } from '@oysterjs/partners/uiv2/input';
import { Select } from '@oysterjs/partners/uiv2/select';
import { ProductType } from '../../types/graphql';

const features = [
  {
    name: 'Comprehensive coverage.',
    description:
      'Oyster works with 30+ carriers to ensure you get tailored coverage for your business.',
    icon: ShieldCheckIcon
  },
  {
    name: 'Digital experience.',
    description: 'Experience a seamless digital experience from quote to policy management.',
    icon: ComputerDesktopIcon
  },
  {
    name: 'Risk management.',
    description:
      'Generate, share, and manage COIs on your own. Get access to a dedicated risk expert.',
    icon: LockClosedIcon
  }
];

const getMerchantInfo = (merchantHandle?: string) => {
  const merchants = {
    '1800d2c': {
      name: '1-800-D2C',
      logo: 'https://cdn.prod.website-files.com/612afc0875843d237ddf95fc/66a279f55ce0fe8f522bbf8a_1800d2c-p-500.png',
      size: 'h-8'
    },
    aircfo: {
      name: 'AirCFO',
      logo: '/images/logos/aircfo.png',
      size: 'h-8'
    },
    bbg: {
      name: 'Balanced Business Group',
      logo: '/images/logos/bbg.png',
      size: 'h-10'
    },
    beyondsku: {
      name: 'BeyondSKU',
      logo: '/images/logos/beyondsku.png',
      size: 'h-10'
    },
    bookkeeper360: {
      name: 'Bookkeeper360',
      logo: '/images/logos/bookkeeper360.svg',
      size: 'h-10'
    },
    cfoplans: {
      name: 'CFO Plans',
      logo: '/images/logos/cfoplans.png',
      size: 'h-10'
    },
    decimal: {
      name: 'Decimal',
      logo: '/images/logos/decimal.png',
      size: 'h-10'
    },
    ecombalance: {
      name: 'EcomBalance',
      logo: '/images/logos/ecombalance.png',
      size: 'h-10'
    },
    finta: {
      name: 'Finta',
      logo: '/images/logos/finta.png',
      size: 'h-10'
    },
    fulfil: {
      name: 'Fulfil',
      logo: '/images/logos/fulfil.png',
      size: 'h-10'
    },
    guidance: {
      name: 'Guidance',
      logo: '/images/logos/guidance.png',
      size: 'h-10'
    },
    hawkemedia: {
      name: 'Hawke Media',
      logo: 'https://cdn.prod.website-files.com/612afc0875843d237ddf95fc/66a279f67cb2a4a80735ff4c_hawkemedia.png',
      size: 'h-8'
    },
    keptpro: {
      name: 'KeptPro',
      logo: '/images/logos/keptpro.png',
      size: 'h-10'
    },
    pacificcrestgroup: {
      name: 'Pacific Crest Group',
      logo: '/images/logos/pacificcrestgroup.jpg',
      size: 'h-10'
    },
    paperchase: {
      name: 'Paperchase',
      logo: '/images/logos/paperchase.png',
      size: 'h-10'
    },
    scharfconsulting: {
      name: 'Scharf Consulting',
      logo: '/images/logos/scharfconsulting.png',
      size: 'h-10'
    },
    pilot: {
      name: 'Pilot',
      logo: 'https://cdn.prod.website-files.com/612afc0875843d237ddf95fc/66a279f500e083184307f7cb_pilot.png',
      size: 'h-10'
    },
    rho: {
      name: 'Rho',
      logo: 'https://cdn.prod.website-files.com/612afc0875843d237ddf95fc/66a279f59a6e8aa01ecb6ad9_rho.png',
      size: 'h-8'
    },
    smartrr: {
      name: 'SmartRR',
      logo: '/images/logos/smartrr.png',
      size: 'h-9'
    },
    steptwo: {
      name: 'StepTwo',
      logo: '/images/logos/steptwo.png',
      size: 'h-10'
    }
  };

  return merchantHandle ? merchants[merchantHandle] : null;
};

export const Landing = (props: { merchantHandle?: string }) => {
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [createApplication, { data, error }] = useMutation<CreateBusinessApplicationMutation>(
    CreateBusinessApplicationDocument
  );

  React.useEffect(() => {
    if (data?.createBusinessApplication?.id) {
      history.push(`/commercial/app/${data.createBusinessApplication.id}`);
    }
  }, [data?.createBusinessApplication?.id]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const form = Object.fromEntries(formData.entries());

    if (form.businessVertical === ProductType.Bike) {
      // Go to fillout
      const filloutParams = new URLSearchParams({
        first_name: form.fullName?.toString().split(' ')[0] || '',
        last_name: form.fullName?.toString().split(' ')[1] || '',
        email: form.email?.toString() || '',
        phone: form.phone?.toString() || '',
        business_type: 'BIKES'
      });
      window.location.href = `https://www.withoyster.com/business-insurance-app?${filloutParams.toString()}`;
      return;
    }

    setIsSubmitting(true);
    setTimeout(() => {
      createApplication({
        variables: {
          application: {
            businessName: form.businessName?.toString(),
            fullName: form.fullName?.toString(),
            email: form.email?.toString(),
            phone: form.phone?.toString()
          }
        }
      }).finally(() => {
        setIsSubmitting(false);
      });
    }, 1000);
  };

  const merchantInfo = getMerchantInfo(props.merchantHandle);

  return (
    <div className="min-h-screen bg-neutral-950 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-8 lg:px-16">
        <div className="mx-auto flex flex-col max-w-2xl gap-x-8 gap-y-16 sm:gap-y-20 lg:max-w-none lg:flex-row">
          <div className="lg:pr-8 w-full">
            <div className="lg:max-w-lg">
              <div className="flex flex-col sm:items-center gap-2 sm:flex-row">
                <OysterWordMark forceLight />
                {merchantInfo?.logo && (
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-neutral-200 mx-2"
                  >
                    <path
                      d="M12 5v14M5 12h14"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                {merchantInfo?.logo && (
                  <img
                    src={merchantInfo.logo}
                    alt={merchantInfo.name}
                    className={clsx(
                      merchantInfo.size,
                      'w-auto object-contain self-start sm:self-center'
                    )}
                  />
                )}
              </div>
              <h1 className="text-base mt-10 font-semibold leading-7 text-primary-300">
                Reduce risk and grow confidently
              </h1>
              <p className="mt-2 text-3xl font-semibold font-heading text-white sm:text-4xl">
                Find the right insurance for your business
              </p>
              {!merchantInfo?.name && (
                <p className="mt-6 text-lg leading-8 text-neutral-300">
                  Oyster works with top-rated insurance carriers to provide you with the best
                  coverage for your business. Plus, leverage Oyster's risk management platform and
                  experts to better protect your business.
                </p>
              )}
              {!!merchantInfo?.name && (
                <p className="mt-6 text-lg leading-8 text-neutral-300">
                  {merchantInfo.name} works with Oyster to offer insurance from top-rated carriers
                  so that you get the best coverage for your business. Plus, leverage Oyster's risk
                  management platform and experts to better protect your business.
                </p>
              )}
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-neutral-300 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-white">
                      <feature.icon
                        aria-hidden="true"
                        className="absolute left-1 top-1 h-5 w-5 text-primary-300"
                      />
                      {feature.name}{' '}
                    </dt>
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex w-full justify-center lg:mt-20">
            <div className="isolate relative bg-neutral-100 px-10 py-10 shadow-2xl rounded-lg sm:px-12 max-w-md w-full">
              <h2 className="mt-2 text-3xl font-medium font-heading">Start your quote</h2>
              <form onSubmit={onSubmit} className="space-y-6 mt-6">
                <Fieldset>
                  <FieldGroup>
                    <Field>
                      <Label>Business name</Label>
                      <Input
                        name="businessName"
                        type="text"
                        required
                        placeholder="ACME Corporation"
                      />
                    </Field>

                    <Field>
                      <Label>Your full name</Label>
                      <Input
                        name="fullName"
                        type="text"
                        required
                        autoComplete="full-name"
                        placeholder="May Peterson"
                      />
                    </Field>

                    <Field>
                      <Label>Email address</Label>
                      <Input
                        name="email"
                        type="email"
                        required
                        autoComplete="email"
                        placeholder="may@acme.com"
                      />
                    </Field>

                    <Field>
                      <Label>Phone number</Label>
                      <Input
                        name="phone"
                        type="tel"
                        required
                        autoComplete="tel"
                        placeholder="+1 (555) 123-4567"
                        minLength={10}
                      />
                    </Field>

                    <Field>
                      <Label>Business Vertical</Label>
                      <Select required name="businessVertical" defaultValue={ProductType.Other}>
                        {[
                          { value: '' },
                          {
                            displayValue: 'Beauty and Supplements',
                            value: ProductType.BeautyAndSupplements
                          },
                          { displayValue: 'Bikes & eBikes', value: ProductType.Bike },
                          { displayValue: 'Collectibles', value: ProductType.Collectibles },
                          { displayValue: 'Electronics', value: ProductType.Electronics },
                          { displayValue: 'Fashion', value: ProductType.Fashion },
                          { displayValue: 'Food and Beverage', value: ProductType.FoodAndBeverage },
                          { displayValue: 'Home and Furniture', value: ProductType.Furniture },
                          { displayValue: 'Jewelry', value: ProductType.Jewelry },
                          { displayValue: 'Motorcycle', value: ProductType.Motorcycle },
                          { displayValue: 'Off-road Vehicles', value: ProductType.OffroadVehicle },
                          { displayValue: 'Other', value: ProductType.Other },
                          { displayValue: 'Outdoor Sports', value: ProductType.OutdoorSports }
                        ].map((opt) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.displayValue}
                          </option>
                        ))}
                      </Select>
                    </Field>
                  </FieldGroup>
                </Fieldset>

                <HeadlessButton
                  type="submit"
                  disabled={isSubmitting}
                  className={clsx(
                    'w-full bg-primary-500 text-white shadow-sm hover:bg-primary-50 hover:text-primary-700 hover:outline hover:outline-primary-700 cursor-pointer',
                    'font-heading transition-all',
                    'mt-6 block rounded-md px-3 py-2 text-center font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500',
                    'data-[disabled]:opacity-75 data-[disabled]:cursor-not-allowed data-[disabled]:hover:bg-primary-500 data-[disabled]:hover:text-white data-[disabled]:hover:outline-none data-[disabled]:focus-visible:outline-none'
                  )}
                >
                  Continue
                </HeadlessButton>
                {!!error?.message && (
                  <div className="mt-2 text-sm/5 text-red-600 sm:text-xs/4">{error.message}</div>
                )}
              </form>

              <div
                aria-hidden="true"
                className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
              >
                <div
                  style={{
                    clipPath:
                      'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)'
                  }}
                  className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
