import React from 'react';
import { RentalPageContainer } from './components';
import { FormContainer, FormRowHeader, FormRow, FormColumn } from '@oysterjs/ui/Form/builder';
import { Spinner } from '@oysterjs/ui/Spinner';
import {
  AssetAccessory,
  AttachmentFile,
  DefaultFileRoles,
  RentalAsset,
  RentalBooking,
  RentalClaim,
  RentalClaimState,
  RentalDamageType,
  ValidationError,
  WaiverEntry
} from '@oysterjs/types';
import {
  getMerchantRentalAssets,
  getRentalClaimByWaiverId,
  createRentalClaim,
  uploadClaimAttachments,
  getRentalClaim,
  updateRentalClaim
} from '@oysterjs/core/api/merchant';
import { WrappedError, ErrorType } from '@oysterjs/core/errors';
import { Select } from '@oysterjs/ui/Form/select';
import { ErrorDisplay, TextAreaInput, TextInput } from '@oysterjs/ui/Form/text';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import { AddressInputForm } from '@oysterjs/ui/Form/address';
import {
  RequirementItemContainer,
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementTitle,
  RequirementDescription
} from '@oysterjs/ui/common';
import { AttachmentUploader } from '@oysterjs/ui/Attachment';
import { Button } from '../../components/Button';
import { DatePicker } from '@oysterjs/ui/Form/datepicker';

const getCurrentTime = (): Date => {
  const date = new Date();
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

export const RentalClaimForm = (props: {
  waiverId: string;
  claimId: string;
  onClaimSubmitted: (claim: RentalClaim, booking?: RentalBooking) => void;
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
  const [booking, setBooking] = React.useState<RentalBooking>();
  const [claim, setClaim] = React.useState<RentalClaim>();
  const [availableAssets, setAvailableAssets] = React.useState<RentalAsset[]>([]);
  const [attachments, setAttachments] = React.useState<AttachmentFile[]>([]);
  const [filesToUpload, setFilesToUpload] = React.useState<File[]>([]);

  const [incidentDate, setIncidentDate] = React.useState<Date>(getCurrentTime());
  const [showDatePicker, setShowDatePicker] = React.useState(false);

  const [error, setError] = React.useState<string>();
  const [inputChanged, setInputChanged] = React.useState(false);
  const [validationError, setValidationError] = React.useState<ValidationError>();

  const fetchData = async () => {
    if (!props.waiverId) {
      return;
    }

    try {
      setLoading(true);
      let res: { Entry: WaiverEntry; Claim?: RentalClaim; Attachments: AttachmentFile[] };
      if (props.waiverId) {
        res = await getRentalClaimByWaiverId(props.waiverId);
      } else if (props.claimId) {
        res = await getRentalClaim(props.claimId);
      } else {
        throw new Error(`No claim ID or waiver ID provided.`);
      }
      setBooking(res.Entry.Booking);
      setAttachments(res.Attachments || []);

      let claim: RentalClaim | undefined = res.Claim;
      if (!claim) {
        claim = {
          ID: '',
          MerchantID: '',
          RentalWaiverID: res.Entry.Waiver?.ID || '',
          State: RentalClaimState.requires_information,
          Details: {
            Assets: res.Entry.Waiver?.Details.Assets || [],
            ClaimNumber: '',
            DamageType: RentalDamageType.unknown,
            IncidentDate: new Date(),
            IncidentCause: '',
            IncidentAddress: {}
          }
        };
      }
      setClaim(claim);

      const assets = await getMerchantRentalAssets();
      setAvailableAssets(assets);
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      if (err.type() === ErrorType.validationError) {
        setValidationError(err.getValidationError());
      } else {
        setValidationError(undefined);
        throw e;
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [props.waiverId]);

  const onChangeClaim = (block: (claim: RentalClaim) => RentalClaim) => {
    setInputChanged(true);
    setClaim((prev) => {
      if (!prev) {
        throw new Error(`Updating claim without being initialized`);
      }

      return { ...block(prev) };
    });
  };

  const onAttachmentsAdded = (addedFiles) => {
    if (validationError?.Field == 'Attachment') {
      setValidationError(undefined);
    }

    setFilesToUpload((prev) => [...prev, ...addedFiles]);
    return Promise.resolve();
  };

  const onAttachmentRemoved = (index) => {
    if (validationError?.Field == 'Attachment') {
      setValidationError(undefined);
    }

    setFilesToUpload([
      ...filesToUpload.slice(0, index),
      ...filesToUpload.slice(index + 1, filesToUpload.length)
    ]);
    return Promise.resolve();
  };

  const getDamageTypeOptions = () => {
    return Object.values(RentalDamageType).map((d) => ({ value: d, displayValue: d }));
  };

  //================================================================
  // ASSETS LOGIC
  //================================================================

  const onAddAsset = () => {
    if (claim) {
      onChangeClaim((currentClaim) => ({
        ...currentClaim,
        Details: {
          ...currentClaim.Details,
          Assets: [
            ...currentClaim.Details.Assets,
            {
              Asset: availableAssets[0],
              Accessories: [],
              Premium: {
                Currency: 'usd',
                Base: 0,
                MerchantFeeAmount: 0,
                OysterFeeAmount: 0,
                ProcessingFeeAmount: 0,
                Total: 0
              }
            }
          ]
        }
      }));
    }
  };

  const onRemoveAsset = () => {
    if (claim) {
      onChangeClaim((currentClaim) => {
        const updatedAssets = currentClaim.Details.Assets;
        updatedAssets.splice(updatedAssets.length - 1, 1);
        return {
          ...currentClaim,
          Details: {
            ...currentClaim.Details,
            Assets: updatedAssets
          }
        };
      });
    }
  };

  const onAddAccessory = (assetIndex: number, accessory: AssetAccessory) => {
    if (claim) {
      const updatedAsset = {
        ...claim.Details.Assets[assetIndex]
      };
      updatedAsset.Accessories = [...updatedAsset.Accessories, accessory];
      const updatedAssets = [
        ...claim.Details.Assets.slice(0, assetIndex),
        updatedAsset,
        ...claim.Details.Assets.slice(assetIndex + 1, claim.Details.Assets.length)
      ];

      onChangeClaim((currentClaim) => {
        return {
          ...currentClaim,
          Details: {
            ...currentClaim.Details,
            Assets: updatedAssets
          }
        };
      });
    }
  };

  const onRemoveAccessory = (assetIndex: number, accessory: AssetAccessory) => {
    if (claim) {
      const updatedAsset = {
        ...claim.Details.Assets[assetIndex]
      };
      const indexToRemove = updatedAsset.Accessories.findIndex(
        (acc) => acc.Name === accessory.Name && acc.Value === accessory.Value
      );
      if (indexToRemove !== -1) {
        updatedAsset.Accessories.splice(indexToRemove, 1);
        const updatedAssets = [
          ...claim.Details.Assets.slice(0, assetIndex),
          updatedAsset,
          ...claim.Details.Assets.slice(assetIndex + 1, claim.Details.Assets.length)
        ];

        onChangeClaim((currentClaim) => {
          return {
            ...currentClaim,
            Details: {
              ...currentClaim.Details,
              Assets: updatedAssets
            }
          };
        });
      }
    }
  };

  //================================================================
  // SUBMISSION LOGIC
  //================================================================

  const onSubmitClaim = async () => {
    if (claim) {
      try {
        setSubmitLoading(true);
        setError(undefined);
        setValidationError(undefined);
        setInputChanged(false);

        const claimToSubmit = {
          ...claim,
          Details: {
            ...claim.Details,
            IncidentDate: incidentDate
          }
        };

        // Submit the claim
        let updatedClaim: RentalClaim;
        if (claim.ID || props.claimId) {
          updatedClaim = await updateRentalClaim(props.claimId, claimToSubmit);
        } else {
          updatedClaim = await createRentalClaim(claimToSubmit);
        }

        // Set the created claim as state claim in case there are
        // any further API errors.
        if (updatedClaim) {
          setClaim(updatedClaim);
        }

        // Upload the files
        if (filesToUpload.length > 0) {
          const updatedFiles = await uploadClaimAttachments(
            updatedClaim.ID,
            DefaultFileRoles.waiverClaimAttachment,
            filesToUpload
          );

          setAttachments(updatedFiles);
          setFilesToUpload([]);
        }

        props.onClaimSubmitted(updatedClaim, booking);
      } catch (e) {
        const err = WrappedError.asWrappedError(e);
        if (err.type() === ErrorType.validationError) {
          setError('');
          setValidationError(err.getValidationError());
        } else {
          setValidationError(undefined);
          setError(err.message);
        }
      } finally {
        setSubmitLoading(false);
      }
    } else {
      throw new Error('Invalid claim state');
    }
  };

  return (
    <RentalPageContainer
      title="Submit a Claim"
      description="Provide details on the loss of your asset during a rental"
    >
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {loading && <Spinner color="#333333" />}
        {claim && (
          <>
            <FormRowHeader title="Assets" />
            {claim.Details.Assets.filter((asset) => !!asset.Asset).map((asset, index) => {
              const getDisplayName = (ast: RentalAsset): string =>
                `${ast.Name}${ast.Details.SerialNumber ? ` [${ast.Details.SerialNumber}]` : ''}`;

              const getAccessoriesQuantity = (
                list: AssetAccessory[],
                accessory: AssetAccessory
              ): number =>
                list.filter((acc) => acc.Name === accessory.Name && acc.Value === accessory.Value)
                  .length;

              return (
                <div key={index}>
                  <FormRow>
                    <FormColumn>
                      <Select
                        options={availableAssets.map((propsAsset) => {
                          return {
                            displayValue: getDisplayName(propsAsset),
                            value: propsAsset.ID
                          };
                        })}
                        value={asset.Asset!.ID}
                        onChange={(value) => {
                          const selectedAsset = availableAssets.find((ast) => ast.ID === value);
                          if (!selectedAsset) {
                            throw new Error('Selected asset not found');
                          }

                          onChangeClaim((currentClaim) => {
                            currentClaim.Details.Assets[index].Asset = selectedAsset;
                            currentClaim.Details.Assets[index].Accessories = [];

                            return currentClaim;
                          });
                        }}
                      />
                    </FormColumn>
                  </FormRow>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '10px',
                      paddingLeft: '20px',
                      paddingRight: '10px'
                    }}
                  >
                    {asset.Asset!.Details.AvailableAccessories.map((acc) => (
                      <div
                        key={acc.Name}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          width: '49%'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '1em',
                            color: '#333333',
                            fontWeight: 500,
                            flexGrow: 1
                          }}
                        >
                          {acc.Name}
                        </div>
                        <Button
                          style={{ margin: '15px', minWidth: '0px' }}
                          onClick={() => onRemoveAccessory(index, acc)}
                        >
                          -
                        </Button>
                        <h4>{getAccessoriesQuantity(asset.Accessories, acc)}</h4>
                        <Button
                          style={{ margin: '15px', minWidth: '0px' }}
                          onClick={() => onAddAccessory(index, acc)}
                        >
                          +
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button style={{ marginRight: '10px', flexGrow: 1 }} onClick={onAddAsset}>
                Add another
              </Button>
              <Button
                disabled={claim.Details.Assets.length <= 1}
                style={{ marginLeft: '10px', flexGrow: 1 }}
                onClick={onRemoveAsset}
              >
                Remove asset
              </Button>
            </div>
            <FormRowHeader title="Incident Details" />
            <FormRow breakMobile>
              <FormColumn title="Incident date">
                <TextInput
                  error={
                    !inputChanged &&
                    validationError?.Field === 'StartDate' &&
                    validationError?.Message
                  }
                  value={incidentDate.toDateString()}
                  disabled={!!submitLoading}
                  onFocus={() => setShowDatePicker(true)}
                />
              </FormColumn>
            </FormRow>
            {showDatePicker && (
              <FormRow>
                <DatePicker
                  loading={loading}
                  onDayClick={(date) => {
                    setShowDatePicker(false);
                    date.setHours(
                      incidentDate.getHours(),
                      incidentDate.getMinutes(),
                      incidentDate.getSeconds()
                    );
                    setIncidentDate(date);
                  }}
                  selected={incidentDate}
                  disabled={(day) => {
                    const today = new Date(new Date().setHours(0, 0, 0, 0));
                    return day.getTime() < today.setDate(today.getDate());
                  }}
                />
              </FormRow>
            )}

            <FormRow breakMobile>
              <FormColumn title="Type of damage">
                <Select
                  options={getDamageTypeOptions()}
                  defaultValue={claim.Details.DamageType}
                  onChange={(v) =>
                    onChangeClaim((c) => {
                      c.Details.DamageType = v;
                      return c;
                    })
                  }
                />
              </FormColumn>
            </FormRow>

            {!inputChanged &&
              validationError?.Field === 'DamageType' &&
              validationError?.Message && <ErrorDisplay>{validationError?.Message}</ErrorDisplay>}

            {(claim.Details.DamageType === RentalDamageType.fire ||
              claim.Details.DamageType === RentalDamageType.theft ||
              claim.Details.DamageType === RentalDamageType.accident) && (
              <>
                {(claim.Details.DamageType === RentalDamageType.theft ||
                  claim.Details.DamageType === RentalDamageType.accident) && (
                  <>
                    <RequirementItemContainer>
                      <RequirementCheckboxContainer>
                        <Checkbox
                          label="PoliceReportFiled"
                          checked={claim.Details.PoliceReportFiled || false}
                          disabled={!!submitLoading}
                          onChange={() =>
                            onChangeClaim((c) => {
                              c.Details.PoliceReportFiled = !c.Details.PoliceReportFiled;
                              return c;
                            })
                          }
                        />
                      </RequirementCheckboxContainer>
                      <RequirementContentContainer>
                        <RequirementTitle>Police report filed</RequirementTitle>
                        <RequirementDescription>
                          Check this box if a police report has been completed and filed.
                        </RequirementDescription>
                      </RequirementContentContainer>
                    </RequirementItemContainer>
                  </>
                )}
                {(claim.Details.DamageType === RentalDamageType.fire ||
                  !!claim.Details.PoliceReportFiled) && (
                  <>
                    <FormRow breakMobile>
                      <FormColumn
                        title={
                          claim.Details.DamageType === RentalDamageType.fire
                            ? 'Name of Fire Department'
                            : 'Name of Police Department'
                        }
                      >
                        <TextInput
                          error={
                            !inputChanged &&
                            validationError?.Field === 'NameOfEmergencyDepartment' &&
                            validationError?.Message
                          }
                          value={claim.Details.NameOfEmergencyDepartment}
                          onChange={(e) => {
                            const value = e.currentTarget.value;
                            onChangeClaim((c) => {
                              c.Details.NameOfEmergencyDepartment = value;
                              return c;
                            });
                          }}
                          disabled={!!submitLoading}
                        />
                      </FormColumn>
                    </FormRow>
                    <FormRow breakMobile>
                      <FormColumn title="Report or Incident Number">
                        <TextInput
                          value={claim.Details.ReportOrIncidentNumber}
                          onChange={(e) => {
                            const value = e.currentTarget.value;
                            onChangeClaim((c) => {
                              c.Details.ReportOrIncidentNumber = value;
                              return c;
                            });
                          }}
                          disabled={!!submitLoading}
                        />
                      </FormColumn>
                    </FormRow>
                  </>
                )}
              </>
            )}
            {claim.Details.DamageType === RentalDamageType.water && (
              <>
                <FormRow breakMobile>
                  <FormColumn title="Source of Water Damage">
                    <TextInput
                      value={claim.Details.SourceOfWaterDamage}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        onChangeClaim((c) => {
                          c.Details.SourceOfWaterDamage = value;
                          return c;
                        });
                      }}
                      disabled={!!submitLoading}
                      error={
                        !inputChanged &&
                        validationError?.Field === 'SourceOfWaterDamage' &&
                        validationError?.Message
                      }
                    />
                  </FormColumn>
                </FormRow>
              </>
            )}
            <FormRowHeader title="Cause of Incident" />
            <TextAreaInput
              value={claim.Details.IncidentCause}
              onChange={(e) => {
                const value = e.currentTarget.value;
                onChangeClaim((c) => {
                  c.Details.IncidentCause = value;
                  return c;
                });
              }}
              disabled={!!submitLoading}
              error={
                !inputChanged &&
                validationError?.Field === 'IncidentCause' &&
                validationError?.Message
              }
            />
            <FormRowHeader title="Location of Incident" />
            <AddressInputForm
              showBusinessName={false}
              showSecondLine={false}
              initialValue={{
                streetAddress: claim.Details.IncidentAddress.AddressLine1,
                streetAddressLine2: claim.Details.IncidentAddress.AddressLine2,
                city: claim.Details.IncidentAddress.AddressCity,
                state: claim.Details.IncidentAddress.AddressCity,
                zipCode: claim.Details.IncidentAddress.AddressZipCode
              }}
              onChange={(addr) =>
                onChangeClaim((c) => {
                  claim.Details.IncidentAddress = {
                    AddressLine1: addr.streetAddress || '',
                    AddressLine2: addr.streetAddressLine2,
                    AddressCity: addr.city || '',
                    AddressState: addr.state || '',
                    AddressZipCode: addr.zipCode || ''
                  };
                  return c;
                })
              }
              validationError={{
                streetAddress:
                  !inputChanged &&
                  validationError?.Field === 'AddressLine1' &&
                  validationError?.Message,
                streetAddressLine2:
                  !inputChanged &&
                  validationError?.Field === 'AddressLine2' &&
                  validationError?.Message,
                city:
                  !inputChanged &&
                  validationError?.Field === 'AddressCity' &&
                  validationError?.Message,
                state:
                  !inputChanged &&
                  validationError?.Field === 'AddressState' &&
                  validationError?.Message,
                zipCode:
                  !inputChanged &&
                  validationError?.Field === 'AddressZipCode' &&
                  validationError?.Message
              }}
            />
            <RequirementItemContainer>
              <RequirementCheckboxContainer>
                <Checkbox
                  label="HasOtherInsuranceCoverage"
                  checked={claim.Details.HasOtherInsuranceCoverage || false}
                  disabled={!!submitLoading}
                  onChange={() =>
                    onChangeClaim((c) => {
                      c.Details.HasOtherInsuranceCoverage = !c.Details.HasOtherInsuranceCoverage;
                      return c;
                    })
                  }
                />
              </RequirementCheckboxContainer>
              <RequirementContentContainer>
                <RequirementTitle>Other Insurance Coverage</RequirementTitle>
                <RequirementDescription>
                  Check this box if you have any other insurance coverage for the asset.
                </RequirementDescription>
              </RequirementContentContainer>
            </RequirementItemContainer>
            <FormRow>
              <AttachmentUploader
                title="Document and Photos"
                description={
                  'In order to support your claim, please upload any fire department or police reports and before/after photos of the assets.'
                }
                attachments={attachments}
                allowDeleteExisting={true}
                onAttachmentsAdded={onAttachmentsAdded}
                onAttachmentRemoved={onAttachmentRemoved}
                validationError={
                  !inputChanged &&
                  validationError?.Field === 'Attachment' &&
                  validationError?.Message
                    ? validationError
                    : undefined
                }
              />
            </FormRow>
            <Button
              primary
              disabled={!!submitLoading}
              loading={!!submitLoading}
              style={{ margin: '24px 10px 0px 0px' }}
              onClick={() => onSubmitClaim()}
            >
              Submit Claim Request
            </Button>
            {error && <ErrorDisplay>error</ErrorDisplay>}
          </>
        )}
      </FormContainer>
    </RentalPageContainer>
  );
};
