import * as React from 'react';
import { IoDownloadOutline, IoOpenOutline } from 'react-icons/io5';
import { Badge } from '@oysterjs/ui/Badge';

import { PageTitle } from '../../../components/Page/section';
import { Action, ActionsContainer } from './table';
import config from '@oysterjs/core/config';
import {
  BusinessInsuranceType,
  BusinessOperationType,
  Merchant,
  MerchantUser,
  ProductType
} from '@oysterjs/types';
import {
  PolicyState,
  FileRole,
  GetCertificateQuery,
  GetCertificateDocument,
  File,
  GenerateCertificateInput,
  useGenerateCertificateMutation,
  InsuranceType,
  AddressInput,
  MerchantFieldsFragment,
  FileFieldsFragment
} from '../../../types/graphql';
import {
  GetMerchantBusinessPolicyDocument,
  GetMerchantBusinessPolicyQuery,
  ValidationError
} from '../../../types/graphql';
import { getDisplayInsuranceType } from '../../../types/map';
import { openPageInNewTab } from '@oysterjs/core/window';
import { useHistory, useLocation } from 'react-router';
import { Slideout } from '@oysterjs/partners/uiv2/slideout';
import ErrorBoundary, { ErrorBoundaryV2 } from '@oysterjs/ui/ErrorBoundary';
import { Loadable } from '@oysterjs/ui/Loadable';
import { getMerchantGraphQLClient } from '@oysterjs/core/api/merchant';
import { Button } from '@oysterjs/partners/uiv2/button';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { DocumentPlusIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { BusinessPolicyFieldsFragment } from '../../../types/graphql';
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label
} from '@oysterjs/partners/uiv2/fieldset';
import { Input } from '@oysterjs/partners/uiv2/input';
import { Textarea } from '@oysterjs/partners/uiv2/textarea';
import { Listbox } from '@oysterjs/partners/uiv2/listbox';
import { AddressForm, RadioField } from '../../commercial/form';
import { TransitionContainer } from '../../commercial/common';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { ApolloError } from '@apollo/client';
import * as Headless from '@headlessui/react';

const getFormId = (): string => {
  switch (config().environment) {
    case 'production':
      return 'vtaJwrn7SEus';
    default:
      return 'ohCv6cYeRaus';
  }
};

const toNumber = (s?: string) => s?.replace(/[^\d.]/g, '');

const getFilloutParameters = (merchant: Merchant, merchantUser: MerchantUser) => ({
  'data-first_name': merchantUser.FirstName,
  'data-last_name': merchantUser.LastName,
  'data-email': merchantUser.Email,
  'data-phone': merchant.BusinessProfile.Phone,
  'data-merchant_id': merchant.ID,
  'data-business_name': merchant.BusinessProfile.Name,
  'data-dba_name': merchant.BusinessProfile.DBA,
  'data-website': merchant.BusinessProfile.Domain,
  'data-address_line_1': merchant.BusinessProfile.Address.AddressLine1,
  'data-address_line_2': merchant.BusinessProfile.Address.AddressLine2,
  'data-address_city': merchant.BusinessProfile.Address.City,
  'data-address_state': merchant.BusinessProfile.Address.Zone,
  'data-address_zip_code': merchant.BusinessProfile.Address.PostalCode,
  'data-business_type':
    {
      [ProductType.bike]: 'BIKES',
      [ProductType.jewelry]: 'JEWELRY',
      [ProductType.electronics]: 'ELECTRONICS'
    }[merchant.BusinessProfile.ProductVerticals?.[0] || ''] ||
    merchant.BusinessProfile.ProductVerticals?.[0],
  'data-insurance_types': merchant.BusinessProfile.Personalization.BusinessInsuranceTypes?.map(
    (t) =>
      ({
        [BusinessInsuranceType.BusinessOwners]: 'BUSINESS_OWNERS',
        [BusinessInsuranceType.CommercialAuto]: 'AUTO',
        [BusinessInsuranceType.Cyber]: 'CYBER',
        [BusinessInsuranceType.DirectorsAndOfficers]: 'DIRECTORS_OFFICERS',
        [BusinessInsuranceType.GeneralLiability]: 'GENERAL_LIABILITY',
        [BusinessInsuranceType.JewelersBlock]: 'JEWELERS_BLOCK',
        [BusinessInsuranceType.Property]: 'PROPERTY',
        [BusinessInsuranceType.Rental]: 'RENTAL',
        [BusinessInsuranceType.ShippingAndTransportation]: 'SHIPPING',
        [BusinessInsuranceType.UmbrellaExcess]: 'UMBRELLA',
        [BusinessInsuranceType.WorkersCompensation]: 'WORKERS_COMP'
      })[t] ||
      merchant.BusinessProfile.Personalization.BusinessInsuranceTypesOtherDesc ||
      t
  ).join(','),
  'data-operation_types': merchant.BusinessProfile.Personalization.BusinessOperationTypes?.map(
    (t) =>
      ({
        [BusinessOperationType.RetailECommerce]: 'RETAIL',
        [BusinessOperationType.ServiceOrRepair]: 'SERVICE',
        [BusinessOperationType.Wholesale]: 'WHOLESALE',
        [BusinessOperationType.Rental]: 'RENTAL',
        [BusinessOperationType.Manufacturing]: 'MANUFACTORING_DESIGN'
      })[t] ||
      merchant.BusinessProfile.Personalization.BusinessOperationTypesOtherDesc ||
      t
  ).join(','),
  'data-own_brand': merchant.BusinessProfile.Personalization.BusinessManufactureOrWholesaleOwnBrand,
  'data-revenue_retail': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownRetail
  ),
  'data-revenue_service': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownServiceOrRepair
  ),
  'data-revenue_wholesale': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownWholesale
  ),
  'data-revenue_rental': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownRental
  ),
  'data-revenue_manufacturing': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownManufacturing
  ),
  'data-revenue_other': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownOther
  ),
  'data-total_employee_payroll': toNumber(
    merchant.BusinessProfile.Personalization.BusinessTotalPayroll
  ),
  'data-number_of_losses': toNumber(
    merchant.BusinessProfile.Personalization.BusinessNumberOfPriorLosses
  )
});

const GetInsurance = (props: { merchant: Merchant; merchantUser: MerchantUser }) => {
  React.useEffect(() => {
    // Create script component.
    const script = document.createElement('script');
    script.id = 'fillout-embed';
    script.src = `//server.fillout.com/embed/v1/`;
    script.defer = true;

    // Add the script to the DOM
    document.head.appendChild(script);

    // Remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const prefilledParameters = getFilloutParameters(props.merchant, props.merchantUser);

  return (
    <>
      <p className="text-base max-w-[750px] mt-0">
        Submit the form to get a tailored quote for your business. Our commercial insurance team of
        experts will reach out soon upon submission.
      </p>
      <div
        className="max-w-[750px] mt-4"
        data-fillout-id={getFormId()}
        data-fillout-embed-type="standard"
        data-fillout-inherit-parameters
        data-fillout-dynamic-resize
        {...prefilledParameters}
      />
    </>
  );
};

const InsuranceFormSubmitted = () => (
  <>
    <p className="text-base max-w-[600px] text-neutral-900 mt-0">
      Thank you for submitting your information. Our commercial insurance team of experts will reach
      out soon.
    </p>
  </>
);

const statuses = {
  [PolicyState.Binding]: 'text-primary-700 bg-primary-50 ring-primary-600/20',
  [PolicyState.Inforce]: 'text-green-700 bg-green-50 ring-green-600/20',
  [PolicyState.Expired]: 'text-neutral-600 bg-neutral-50 ring-neutral-500/10',
  [PolicyState.Canceled]: 'text-red-700 bg-red-50 ring-red-600/10'
};

export const CertificatesTable = (props: { certificates: FileFieldsFragment[] }) => (
  <table className="min-w-full divide-y divide-neutral-200">
    <thead className="bg-neutral-50">
      <tr>
        <th
          scope="col"
          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-neutral-900 sm:pl-6 lg:pl-8"
        >
          Certificate Holder
        </th>
        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900">
          Certificate Number
        </th>
        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900">
          Creation Date
        </th>
        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
          <span className="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
    <tbody className="divide-y divide-neutral-200 bg-white">
      {props.certificates.map((certificate) => (
        <tr key={certificate.id}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-neutral-900 sm:pl-6 lg:pl-8">
            {certificate.details?.certificateHolder.name}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
            {certificate.details?.certificateNumber}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
            {new Intl.DateTimeFormat('en-US').format(new Date(certificate.createdAt))}
          </td>
          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
            <NavLink
              to={`/insurance/certificates/certificate/${certificate.details?.certificateNumber}`}
              className="text-primary-600 hover:text-primary-900"
            >
              View
              <span className="sr-only">, {certificate.details?.certificateNumber}</span>
            </NavLink>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const ManageBusinessInsurance = (props: {
  merchant: Merchant;
  merchantUser: MerchantUser;
  graphMerchant: MerchantFieldsFragment;
  policies: BusinessPolicyFieldsFragment[];
}) => {
  const urlParams = new URLSearchParams(
    Object.fromEntries(
      Object.entries(getFilloutParameters(props.merchant, props.merchantUser)).map(
        ([key, value]) => [key.replace(/^data-/, ''), value]
      )
    )
  );

  const filloutLink =
    config().environment === 'production'
      ? 'https://www.withoyster.com/business-insurance-app'
      : `https://form.fillout.com/t/${getFormId()}?${urlParams.toString()}`;

  const certificates = props.graphMerchant.files
    .filter((f) => f.role === FileRole.Certificate)
    .filter((f) => !!f.details?.certificateNumber);

  return (
    <div className="space-y-8">
      <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div>
              <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                Your Coverage
              </h3>
              <p className="text-sm text-neutral-500">
                Details about your business insurance policies
              </p>
            </div>
            <div className="flex-shrink-0 mt-2 sm:mt-0">
              <Button href={filloutLink} type="button" color="sky">
                Apply for new coverage
              </Button>
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-neutral-200">
                <thead className="bg-neutral-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-neutral-900 sm:pl-6 lg:pl-8"
                    >
                      Coverage
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Carrier
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Expiration Date
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-neutral-200 bg-white">
                  {props.policies.map((policy) => (
                    <tr key={policy.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 lg:pl-8">
                        <div className="text-sm font-medium text-neutral-900">
                          {getDisplayInsuranceType(policy.type)}
                        </div>
                        <div className="text-xs mt-1 text-neutral-500">
                          {policy.policyNumber || 'N/A'}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                        <div
                          className={clsx(
                            statuses[policy.state],
                            'inline-flex rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
                          )}
                        >
                          {titleCase(policy.state)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                        {policy.carrier.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                        {!policy.expiresAt || isNaN(new Date(policy.expiresAt).getTime())
                          ? 'N/A'
                          : new Intl.DateTimeFormat('en-US').format(new Date(policy.expiresAt))}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                        <NavLink
                          to={`/insurance/overview/policy/${policy.id}`}
                          className="text-primary-600 hover:text-primary-900 cursor-pointer"
                        >
                          View
                          <span className="sr-only">, {getDisplayInsuranceType(policy.type)}</span>
                        </NavLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div>
              <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                Your Certificates
              </h3>
              <p className="text-sm text-neutral-500">
                Certificates of insurance you have created with Oyster
              </p>
            </div>
            {certificates.length > 0 && (
              <div className="flex-shrink-0 mt-2 sm:mt-0">
                <Button href="/insurance/certificates/create" type="button" color="sky">
                  Request Certificate
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="px-4 sm:px-6">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              {certificates.length === 0 && (
                <div className="text-center p-8">
                  <DocumentPlusIcon className="mx-auto h-12 w-12 text-neutral-400" />
                  <h4 className="mt-2 text-sm font-semibold text-neutral-900">No certiciates</h4>
                  <p className="mt-1 text-sm text-neutral-500">
                    Get started by creating a new certificate.
                  </p>
                  <div className="mt-6">
                    <Button href="/insurance/certificates/create" type="button" color="sky">
                      Create Certificate
                    </Button>
                  </div>
                </div>
              )}
              {certificates.length > 0 && <CertificatesTable certificates={certificates} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getPolicyBadge = (state: PolicyState) => {
  switch (state) {
    case PolicyState.Binding:
      return <Badge color="rgba(252, 227, 193)" textColor="rgba(214, 131, 0)" label="Pending" />;

    case PolicyState.Inforce:
      return <Badge color="rgba(189, 234, 205)" textColor="rgba(57, 145, 88)" label="Active" />;

    case PolicyState.Expired:
      return <Badge color="rgb(255, 193, 185)" textColor="rgb(201, 67, 48)" label="Expired" />;

    default:
      return <></>;
  }
};

const titleCase = (s: string) =>
  s
    .split(/[\s_]/g)
    .map((part) => part[0].toUpperCase() + part.substring(1).toLowerCase())
    .join(' ');

const unescapeSafe = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

const ViewBusinessPolicy = (props: { policy: BusinessPolicyFieldsFragment }) => (
  <div className="flex flex-col gap-4 mt-4">
    {[
      ['Carrier', props.policy.carrier.name],
      [
        'Premium',
        new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' }).format(
          props.policy.pricing.premium
        )
      ],
      [
        'Total Charge',
        new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' }).format(
          props.policy.pricing.total
        )
      ],
      ['Payment Mode', titleCase(props.policy.pricing.billingType)]
    ].map(([label, description]) => (
      <div key={label} className="flex flex-col gap-1">
        <div className="font-medium">{label}</div>
        <div className="text-neutral-500 text-sm">{description}</div>
      </div>
    ))}
    <div className="flex flex-col gap-1">
      <div className="font-medium">Documents</div>
      {!props.policy.files.length && (
        <div className="text-neutral-500 text-sm">No documents available yet</div>
      )}
      {!!props.policy.files.length && (
        <ul className="text-neutral-500 text-sm list-none m-0 p-0">
          {props.policy.files
            .filter((f) => !!f.url)
            .map((f) => (
              <li>
                <a
                  href={f.url || ''}
                  target="_BLANK"
                  className="text-primary-600 hover:text-primary-900 hover:underline flex items-center gap-1"
                >
                  {titleCase(f.role)} <IoOpenOutline />
                </a>
              </li>
            ))}
        </ul>
      )}
    </div>
  </div>
);

const ViewCertificate = (props: { certificate: File }) => (
  <>
    <ActionsContainer>
      <Action
        onClick={() =>
          openPageInNewTab(
            `${config().serviceBaseUrl.partners}/certificate/${
              props.certificate.details?.certificateNumber
            }`
          )
        }
      >
        Open Sharable Link <IoOpenOutline />
      </Action>
      <Action onClick={() => openPageInNewTab(props.certificate.url || '')}>
        Download Certificate <IoDownloadOutline />
      </Action>
    </ActionsContainer>
    <div className="flex flex-col gap-4 mt-4">
      {[
        ['Insured Name', unescapeSafe(props.certificate.details?.insured.name || '')],
        ['Insured Address', unescapeSafe(props.certificate.details?.insured.address || '')],
        [
          'Certificate Holder Name',
          unescapeSafe(props.certificate.details?.certificateHolder.name || '')
        ],
        [
          'Certificate Holder Address',
          unescapeSafe(props.certificate.details?.certificateHolder.address || '')
        ],
        [
          'Description of Operations',
          unescapeSafe(props.certificate.details?.descriptionOfOperations || '')
        ]
      ].map(
        ([label, description]) =>
          !!description && (
            <div key={label} className="flex flex-col gap-1">
              <div className="font-medium">{label}</div>
              <div className="text-neutral-500 text-sm">{description}</div>
            </div>
          )
      )}
    </div>
  </>
);

type AdditionalInsured = {
  id: string;
  type: string;
  address: AddressInput | null;
  title: string;
  description: string;
  policyType: InsuranceType;
  policyId: string;
};

const CreateCertificate = (props: { policies: BusinessPolicyFieldsFragment[] }) => {
  const history = useHistory();
  const [generateCertificate, { loading }] = useGenerateCertificateMutation();

  const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([]);
  const [error, setError] = React.useState<ApolloError | undefined>();
  const [needsAgentAction, setNeedsAgentAction] = React.useState<boolean>(false);

  // Define the form variables
  const [form, setForm] = React.useState<
    Omit<GenerateCertificateInput, 'additionalInsured'> & {
      additionalInsured: boolean | null;
    }
  >({
    additionalInsured: null,
    additionalInsuredRelationship: null,
    additionalInsuredRelationshipDescription: null,
    address: { line1: '', line2: '', city: '', zone: '', postalCode: '' },
    name: '',
    notes: ''
  });

  // Define the list of additional insureds that the user can select
  const [selectedAdditionalInsured, setSelectedAdditionalInsured] =
    React.useState<AdditionalInsured | null>(null);
  const additionalInsureds: AdditionalInsured[] = [
    ...(props.policies
      .filter(
        (p) =>
          p.state === PolicyState.Inforce &&
          [
            InsuranceType.BusinessOwners,
            InsuranceType.CommercialAuto,
            InsuranceType.GeneralLiability,
            InsuranceType.Package
          ].includes(p.type)
      )
      .flatMap(
        (p) =>
          p.details?.blanketAdditionalInsureds?.map((b) => ({
            id: b.type + ' /// ' + p.id + ' /// ' + 'blanket',
            type: 'blanket',
            address: null,
            title: b.type,
            description: b.description,
            policyType: p.type,
            policyId: p.id
          })) || []
      ) || []),
    ...(props.policies
      .filter(
        (p) =>
          p.state === PolicyState.Inforce &&
          [
            InsuranceType.BusinessOwners,
            InsuranceType.CommercialAuto,
            InsuranceType.GeneralLiability,
            InsuranceType.Package
          ].includes(p.type)
      )
      .flatMap(
        (p) =>
          p.details?.namedAdditionalInsureds?.map((n) => ({
            id: n.name + ' /// ' + p.id + ' /// ' + 'named',
            type: 'named',
            address: n.address,
            title: n.name,
            description: n.address.line1,
            policyType: p.type,
            policyId: p.id
          })) || []
      ) || [])
  ];

  // Define an update handler for the form
  const updateFormMulti = (
    fieldPartsList: string[][],
    update: (prev: typeof form) => typeof form
  ) => {
    fieldPartsList.forEach((fieldParts) =>
      setValidationErrors((prev) =>
        prev.filter(
          (e) =>
            e.field.length !== fieldParts.length || !e.field.every((v, i) => v === fieldParts[i])
        )
      )
    );
    setForm((prev) => update(prev));
  };

  const updateForm = (fieldParts: string[], update: (prev: typeof form) => typeof form) =>
    updateFormMulti([fieldParts], update);

  // Get the error message for a specific field
  const getError = (fieldParts: string[]) =>
    validationErrors.find(
      (e) => e.field.length === fieldParts.length && e.field.every((v, i) => v === fieldParts[i])
    )?.message;

  // Handle form submission, i.e. updating the application on the backend.
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const res = await generateCertificate({
        variables: {
          req: {
            ...form,
            additionalInsured: form.additionalInsured || false,
            additionalInsuredRelationship: form.additionalInsured
              ? selectedAdditionalInsured?.title || 'other'
              : null
          }
        },
        errorPolicy: 'all'
      });

      const validationErrors =
        res.errors
          ?.filter((e) => !!e.extensions?.validationError)
          ?.map((e) => e.extensions?.validationError as ValidationError) || [];

      const otherErrors = res.errors?.filter((e) => !e.extensions?.validationError) || [];
      if (otherErrors.length > 0) {
        throw new ApolloError({ graphQLErrors: otherErrors });
      }

      setValidationErrors(validationErrors);

      if (res.data && !res.data.generateCertificate && validationErrors.length === 0) {
        setNeedsAgentAction(true);
      } else if (res.data?.generateCertificate?.details?.certificateNumber) {
        history.push(
          `/insurance/certificates/certificate/${res.data?.generateCertificate?.details?.certificateNumber}`
        );
      }
    } catch (e) {
      setError(e as ApolloError);
    }
  };

  // Reset the form
  const onReset = () => {
    setForm({
      name: '',
      address: { line1: '', line2: '', city: '', zone: '', postalCode: '' },
      additionalInsured: null,
      additionalInsuredRelationship: null,
      additionalInsuredRelationshipDescription: '',
      notes: ''
    });
    setNeedsAgentAction(false);
  };

  if (needsAgentAction) {
    return (
      <>
        <div className="rounded-md bg-green-50 p-4 mt-4 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-600" />
            </div>
            <div className="ml-4">
              <h3 className="text-sm font-medium text-green-800 mt-0">Request Received</h3>
              <p className="mt-1 text-sm text-green-800/75">
                Your request has been received. An agent will follow up via email with your
                completed certificate.
              </p>
            </div>
          </div>
        </div>
        <Button onClick={onReset} type="button" color="white">
          Create another certificate
        </Button>
      </>
    );
  }

  return (
    <TransitionContainer className="mt-4">
      <form onSubmit={onSubmit}>
        <Fieldset>
          <FieldGroup>
            <RadioField
              label="Does the certificate holder need to be specified as an additional insured?"
              options={[
                { displayText: 'Yes', value: 'true' },
                { displayText: 'No', value: 'false' }
              ]}
              value={form.additionalInsured?.toString() || ''}
              onChange={(val) => {
                updateForm(['additionalInsured'], (prev) => ({
                  ...prev,
                  additionalInsured: val === 'true'
                }));
              }}
              error={getError(['additionalInsured'])}
            />

            {form.additionalInsured && (
              <>
                {additionalInsureds.length > 0 && (
                  <Field>
                    <Label>Relationship to Insured</Label>
                    <Listbox
                      value={selectedAdditionalInsured}
                      placeholder="Choose one..."
                      onChange={(val) => {
                        setSelectedAdditionalInsured(val);
                        updateForm(['additionalInsuredRelationship'], (prev) => ({
                          ...prev,
                          additionalInsuredRelationship: val?.title || null,
                          additionalInsuredRelationshipDescription: null
                        }));
                        if (val?.type === 'named') {
                          updateFormMulti(
                            [
                              ['name'],
                              ['address', 'line1'],
                              ['address', 'line2'],
                              ['address', 'city'],
                              ['address', 'zone'],
                              ['address', 'postalCode']
                            ],
                            (prev) => ({
                              ...prev,
                              name: val?.title || prev.name,
                              address: val?.address || prev.address
                            })
                          );
                        } else {
                          updateFormMulti(
                            [
                              ['name'],
                              ['address', 'line1'],
                              ['address', 'line2'],
                              ['address', 'city'],
                              ['address', 'zone'],
                              ['address', 'postalCode']
                            ],
                            (prev) => ({
                              ...prev,
                              name: '',
                              address: {
                                line1: '',
                                line2: '',
                                city: '',
                                zone: '',
                                postalCode: ''
                              }
                            })
                          );
                        }
                      }}
                      invalid={!!getError(['additionalInsuredRelationship'])}
                      className="w-full truncate"
                    >
                      {additionalInsureds.map((ai) => (
                        <AdditionalInsuredListOption
                          key={ai.id}
                          value={ai}
                          type={ai.type}
                          title={ai.title}
                          description={ai.description}
                        />
                      ))}
                      <AdditionalInsuredListOption
                        type=""
                        title="Other"
                        description="Select this option if the relationship is not listed above"
                        value={{
                          id: 'other',
                          type: 'other',
                          address: null,
                          title: 'Other',
                          description: 'Select this option if the relationship is not listed above',
                          policyType: props.policies[0].type,
                          policyId: props.policies[0].id
                        }}
                      />
                    </Listbox>
                    <ErrorMessage>{getError(['additionalInsuredRelationship'])}</ErrorMessage>
                  </Field>
                )}
                {(additionalInsureds.length === 0 || selectedAdditionalInsured?.id === 'other') && (
                  <Field>
                    <Label>Describe the relationship</Label>
                    <Description>
                      Describe the relationship between the certificate holder and the insured.
                    </Description>
                    <Input
                      value={form.additionalInsuredRelationshipDescription || ''}
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        updateForm(['additionalInsuredRelationshipDescription'], (prev) => ({
                          ...prev,
                          additionalInsuredRelationshipDescription: val
                        }));
                      }}
                      invalid={!!getError(['additionalInsuredRelationshipDescription'])}
                    />
                    <ErrorMessage>
                      {getError(['additionalInsuredRelationshipDescription'])}
                    </ErrorMessage>
                  </Field>
                )}
                {(additionalInsureds.length === 0 || selectedAdditionalInsured?.id === 'other') && (
                  <div className="rounded-md bg-amber-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-amber-600"
                        />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-sm font-medium text-amber-800 mt-0">
                          Additional Review Required
                        </h3>
                        <p className="mt-1 text-sm text-amber-800/75">
                          In order to make sure your policy extends coverage to this party, Oyster
                          will perform a manual review. In most cases, you'll receive your
                          certificate within 24 hours, but the process may take up to several
                          business days depending on complexity. Our agents will follow up via
                          email.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </FieldGroup>

          {form.additionalInsured !== null && (
            <FieldGroup>
              <Field>
                <Label>Certificate Holder</Label>
                <Description>
                  Enter the name of the party that will receive and hold the certificate.
                </Description>
                <Input
                  value={form.name}
                  disabled={selectedAdditionalInsured?.type === 'named'}
                  placeholder="ACME Inc."
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    updateForm(['name'], (prev) => ({ ...prev, name: val }));
                  }}
                  invalid={!!getError(['name'])}
                />
                <ErrorMessage>{getError(['name'])}</ErrorMessage>
              </Field>
            </FieldGroup>
          )}

          {form.additionalInsured !== null && (
            <AddressForm
              disabled={selectedAdditionalInsured?.type === 'named'}
              addressTitle="Certificate Holder Address"
              addressDescription="Enter the address of the party that will receive and hold the certificate."
              initialValue={{
                line1: form.address.line1 || '',
                line2: form.address.line2 || '',
                city: form.address.city || '',
                zone: form.address.zone || '',
                postalCode: form.address.postalCode || ''
              }}
              onChange={(update) =>
                updateFormMulti(
                  [
                    ['address', 'line1'],
                    ['address', 'line2'],
                    ['address', 'city'],
                    ['address', 'zone'],
                    ['address', 'postalCode']
                  ],
                  (prev) => ({
                    ...prev,
                    address: update(prev.address)
                  })
                )
              }
              validationErrors={{
                line1: getError(['address', 'line1']),
                line2: getError(['address', 'line2']),
                city: getError(['address', 'city']),
                zone: getError(['address', 'zone']),
                postalCode: getError(['address', 'postalCode'])
              }}
            />
          )}

          {form.additionalInsured !== null && (
            <FieldGroup>
              <Field>
                <Label>Notes</Label>
                <Description>
                  Additional notes to include for the Oyster team to review.
                </Description>
                <Textarea
                  rows={3}
                  value={form.notes}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    updateForm(['notes'], (prev) => ({ ...prev, notes: val }));
                  }}
                  invalid={!!getError(['notes'])}
                />
                <ErrorMessage>{getError(['notes'])}</ErrorMessage>
              </Field>
            </FieldGroup>
          )}
        </Fieldset>

        {form.additionalInsured !== null && (
          <Button type="submit" color="sky" className="mt-4" disabled={loading}>
            Request Certificate
          </Button>
        )}

        {error && (
          <div className="mt-2">
            <ErrorMessage>{error.message}</ErrorMessage>
          </div>
        )}
      </form>
    </TransitionContainer>
  );
};

const AdditionalInsuredListOption = (props: {
  type: string;
  title: string;
  description: string;
  value: AdditionalInsured;
}) => {
  const sharedClasses = clsx(
    // Base
    'flex flex-grow flex-col max-w-[26rem] col-start-2'
  );

  return (
    <Headless.ListboxOption as={React.Fragment} {...props}>
      {({ selectedOption }) => {
        if (selectedOption) {
          return (
            <div className={clsx(sharedClasses)}>
              <div className="truncate font-medium">{props.title}</div>
              <div className="flex flex-1 overflow-hidden text-neutral-500 group-data-[focus]/option:text-white dark:text-neutral-400">
                <span className="flex-1 truncate">{props.description}</span>
              </div>
            </div>
          );
        }

        return (
          <div
            className={clsx(
              // Basic layout
              'group/option grid cursor-pointer grid-cols-[theme(spacing.5),1fr] items-baseline gap-x-2 rounded-lg py-2.5 pl-2 pr-3.5 sm:grid-cols-[theme(spacing.4),1fr] sm:py-1.5 sm:pl-1.5 sm:pr-3',
              // Typography
              'text-base/6 text-neutral-950 sm:text-sm/6 dark:text-white forced-colors:text-[CanvasText]',
              // Focus
              'outline-none data-[focus]:bg-primary-500 data-[focus]:text-white',
              // Forced colors mode
              'forced-color-adjust-none forced-colors:data-[focus]:bg-[Highlight] forced-colors:data-[focus]:text-[HighlightText]',
              // Disabled
              'data-[disabled]:opacity-50'
            )}
          >
            <div className="self-start">
              <svg
                className="relative hidden size-5 self-baseline stroke-current group-data-[selected]/option:inline sm:size-4"
                viewBox="0 0 16 16"
                fill="none"
                aria-hidden="true"
              >
                <path
                  d="M4 8.5l3 3L12 4"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className={clsx(sharedClasses)}>
              <div className="truncate font-medium">{props.title}</div>
              <div className="flex flex-1 overflow-hidden text-neutral-500 group-data-[focus]/option:text-white dark:text-neutral-400">
                <span className="flex-1">{props.description}</span>
              </div>
            </div>
          </div>
        );
      }}
    </Headless.ListboxOption>
  );
};

export const InsurancePage = (props: {
  merchant: Merchant;
  merchantUser: MerchantUser;
  graphMerchant: MerchantFieldsFragment;
  policies?: BusinessPolicyFieldsFragment[];
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <PageTitle
        title="Business Insurance"
        description="View and manage your business insurance policies with Oyster"
      />

      {!props.merchant.BusinessProfile.Personalization.BusinessInsuranceFilloutFormSubmitted &&
        !props.policies?.length && (
          <GetInsurance merchant={props.merchant} merchantUser={props.merchantUser} />
        )}
      {props.merchant.BusinessProfile.Personalization.BusinessInsuranceFilloutFormSubmitted &&
        !props.policies?.length && <InsuranceFormSubmitted />}
      {!!props.policies?.length && (
        <ManageBusinessInsurance
          merchant={props.merchant}
          merchantUser={props.merchantUser}
          graphMerchant={props.graphMerchant}
          policies={props.policies}
        />
      )}

      <Slideout
        showing={/^\/insurance\/overview\/policy\/.+$/.test(location.pathname)}
        onClose={() => history.replace('/insurance/overview')}
        title="View Policy"
      >
        <ErrorBoundary forceMobile>
          {!location.pathname.match(/\/policy\/(.+)$/)?.[1] ? null : (
            <Loadable
              request={getMerchantGraphQLClient()
                .query<GetMerchantBusinessPolicyQuery>({
                  query: GetMerchantBusinessPolicyDocument,
                  variables: {
                    id: location.pathname.match(/\/policy\/(.+)$/)?.[1]
                  }
                })
                .then((res) => {
                  if (res.data?.businessPolicy) {
                    return res.data.businessPolicy;
                  }

                  throw new Error('Policy not found');
                })}
            >
              {(data) => (
                <PolicySlideoutContainer
                  title={getDisplayInsuranceType(data.type)}
                  description={data.policyNumber}
                  badge={getPolicyBadge(data.state)}
                >
                  <ViewBusinessPolicy policy={data} />
                </PolicySlideoutContainer>
              )}
            </Loadable>
          )}
        </ErrorBoundary>
      </Slideout>

      <Slideout
        showing={/^\/insurance\/certificates\/certificate\/.+$/.test(location.pathname)}
        onClose={() => history.replace('/insurance/certificates')}
        title="View Certificate"
      >
        <ErrorBoundary forceMobile>
          {!location.pathname.match(/\/certificate\/(.+)$/)?.[1] ? null : (
            <Loadable
              request={getMerchantGraphQLClient()
                .query<GetCertificateQuery>({
                  query: GetCertificateDocument,
                  variables: {
                    certificateNumber: location.pathname.match(/\/certificate\/(.+)$/)?.[1]
                  }
                })
                .then((res) => {
                  if (res.data?.certificate?.details) {
                    return res.data.certificate;
                  }

                  throw new Error('Certificate not found');
                })}
            >
              {(data) => (
                <PolicySlideoutContainer
                  title="Certificate of Insurance"
                  description={data.details?.certificateNumber || ''}
                  badge={getPolicyBadge(PolicyState.Inforce)}
                >
                  <ViewCertificate certificate={data as File} />
                </PolicySlideoutContainer>
              )}
            </Loadable>
          )}
        </ErrorBoundary>
      </Slideout>

      <Slideout
        showing={/^\/insurance\/certificates\/create$/.test(location.pathname)}
        onClose={() => history.replace('/insurance/certificates')}
        title="Create Certificate"
      >
        <ErrorBoundaryV2>
          <PolicySlideoutContainer
            title="Enter Certificate Details"
            description="Automatically produce a document that certifies your insurance coverage for a third party"
          >
            <CreateCertificate policies={props.policies || []} />
          </PolicySlideoutContainer>
        </ErrorBoundaryV2>
      </Slideout>
    </>
  );
};

interface PolicySlideoutContainerProps {
  title: string;
  description: string;
  badge?: JSX.Element;
}

export const PolicySlideoutContainer = (
  props: React.PropsWithChildren<PolicySlideoutContainerProps>
) => (
  <>
    <h2 className="text-xl font-semibold mt-0 text-neutral-900">{props.title}</h2>
    <p className="text-sm text-neutral-500">{props.description}</p>
    {props.children}
  </>
);
