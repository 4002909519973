import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { usePlaidLink } from 'react-plaid-link';
import styled from 'styled-components';

import { setMerchantPayment, connectToPlaid } from '@oysterjs/core/api/plaid';
import { Address, MercuryPaymentTypes, PaymentMethodType, ValidationError } from '@oysterjs/types';
import { Button } from '@oysterjs/partners/uiv2/button';
import { ErrorType, WrappedError } from '@oysterjs/core/errors';
import { AddressForm } from '../../commercial/form';
import { ErrorMessage, Field, FieldGroup, Fieldset, Label } from '@oysterjs/partners/uiv2/fieldset';
import { Checkbox as HeadlessCheckbox } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 20px;
`;

const SummerFieldContainer = styled.div`
  flex-direction: column;
`;

const SummaryHeaderDescription = styled.div`
  font-size: 0.9em;
`;

const SummaryHeaderTitle = styled.div`
  font-weight: 500;
`;

interface SummaryHeaderProps {
  title?: string;
  description?: string;
  address?: Partial<Address>;
}

const getMercuryPaymentMethod = (paymentMethod: PaymentMethodType) => {
  switch (paymentMethod) {
    case PaymentMethodType.Check:
      return MercuryPaymentTypes.check;
    case PaymentMethodType.Electronic:
      return MercuryPaymentTypes.electronic;
    default:
      return MercuryPaymentTypes.unknown;
  }
};

export const SummaryField: React.FunctionComponent<React.PropsWithChildren<SummaryHeaderProps>> = (
  props
) => (
  <SummerFieldContainer>
    {props.title && <SummaryHeaderTitle>{props.title}</SummaryHeaderTitle>}
    {props.description && (
      <SummaryHeaderDescription style={{ paddingTop: '5px' }}>
        {props.description}
      </SummaryHeaderDescription>
    )}
    {props.address && (
      <div style={{ paddingTop: '5px' }}>
        <SummaryHeaderDescription>{props.address.AddressLine1}</SummaryHeaderDescription>
        <SummaryHeaderDescription>{props.address.AddressLine2}</SummaryHeaderDescription>
        <SummaryHeaderDescription>
          {props.address.City + ', ' + props.address.Zone + ' ' + props.address.PostalCode}
        </SummaryHeaderDescription>
      </div>
    )}
  </SummerFieldContainer>
);

export const PayoutSummary = (props: {
  paymentMethod: PaymentMethodType;
  address: Partial<Address>;
  onUpdatePayoutsInfo: (paymentMethod: PaymentMethodType, formAddress?: Partial<Address>) => void;
}) => {
  return (
    <SummaryContainer>
      <SummaryField title="Billing Address" address={props.address} />
      <SummaryField title="Payout Method" description={props.paymentMethod} />
    </SummaryContainer>
  );
};

export const UserPayment = (props: {
  loading: boolean;
  paymentMethod?: PaymentMethodType;
  address?: Partial<Address>;
  onUpdatePayoutsInfo: (paymentMethod: PaymentMethodType, formAddress?: Partial<Address>) => void;
  onSave?: () => void;
  error?: string;
  onUpdateLoading: (loading: boolean) => void;
}) => {
  const [data, setData] = React.useState('');
  const [paymentMethod, setPaymentMethod] = React.useState(
    props.paymentMethod || PaymentMethodType.Unknown
  );
  const [token, setToken] = React.useState('');
  const [formAddress, setFormAddress] = React.useState({
    Address: props.address
  });
  const [validationError, setValidationError] = React.useState<ValidationError>();

  const fetchToken = useCallback(async () => {
    const res = await connectToPlaid();
    setData(res.LinkToken);
  }, []);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  const onSuccess = useCallback(async (token) => {
    setPaymentMethod(PaymentMethodType.Electronic);
    if (validationError?.Field == 'PayoutMethod') {
      setValidationError(undefined);
    }
    setToken(token);
  }, []);

  const config = {
    token: data,
    onSuccess
  };

  const { open, ready, error } = usePlaidLink(config);

  const onClickCheck = () => {
    if (validationError?.Field == 'PayoutMethod') {
      setValidationError(undefined);
    }
    setPaymentMethod(PaymentMethodType.Check);
  };

  const onClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onUpdateLoading(true);
    setMerchantPayment({
      PublicToken: token,
      PaymentType: getMercuryPaymentMethod(paymentMethod),
      Address: formAddress.Address
    })
      .then(() => {
        props.onUpdatePayoutsInfo(paymentMethod, formAddress.Address);
        props.onSave?.();
      })
      .catch((e) => {
        const err = WrappedError.asWrappedError(e);
        if (err.type() == ErrorType.validationError) {
          setValidationError(err.getValidationError);
        }
      });
  };

  return (
    <>
      <form className="max-w-xl" onSubmit={onClick}>
        <Fieldset>
          <AddressForm
            addressTitle="Business Address"
            initialValue={{
              line1: formAddress.Address?.AddressLine1 || '',
              line2: formAddress.Address?.AddressLine2 || '',
              city: formAddress.Address?.City || '',
              zone: formAddress.Address?.Zone || '',
              postalCode: formAddress.Address?.PostalCode || ''
            }}
            onChange={(address) =>
              setFormAddress((prev) => {
                const newAddress = address({
                  line1: prev.Address?.AddressLine1 || '',
                  line2: prev.Address?.AddressLine2 || '',
                  city: prev.Address?.City || '',
                  zone: prev.Address?.Zone || '',
                  postalCode: prev.Address?.PostalCode || ''
                });

                return {
                  ...prev,
                  Address: {
                    AddressLine1: newAddress.line1,
                    AddressLine2: newAddress.line2 || '',
                    City: newAddress.city,
                    Zone: newAddress.zone,
                    PostalCode: newAddress.postalCode
                  }
                };
              })
            }
          />
          <FieldGroup>
            <Field>
              <Label>Choose Payout Method</Label>
              <div data-slot="control" className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <HeadlessCheckbox
                  value={paymentMethod}
                  checked={paymentMethod == PaymentMethodType.Check}
                  onClick={onClickCheck}
                  aria-label="Receive Check"
                  aria-description="We will send you a check to your business address."
                  disabled={props.loading}
                  className={clsx([
                    'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[disabled]:pointer-events-none',
                    'group relative flex cursor-pointer rounded-lg bg-white p-4 shadow-sm focus:outline-none border border-neutral-950/10 data-[hover]:border-neutral-950/20  data-[focus]:border-primary-500 data-[focus]:ring-2 data-[focus]:ring-primary-500 dark:bg-neutral-800 dark:border-white/10 dark:data-[hover]:border-white/20'
                  ])}
                >
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <span className="block text-sm font-medium text-neutral-900 dark:text-white">
                        Receive Check
                      </span>
                      <span className="mt-1 flex items-center text-xs text-neutral-500 dark:text-neutral-400 group-data-[disabled]:text-neutral-600 dark:group-data-[disabled]:text-neutral-300">
                        We will send you a check to your business address.
                      </span>
                    </span>
                  </span>
                  <CheckCircleIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-primary-500 [.group:not([data-checked])_&]:invisible"
                  />
                  <span
                    aria-hidden="true"
                    className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-primary-500"
                  />
                </HeadlessCheckbox>
                <HeadlessCheckbox
                  value={paymentMethod}
                  checked={paymentMethod == PaymentMethodType.Electronic}
                  onClick={() => open()}
                  aria-label="Connect Bank Account"
                  aria-description="We will send you an ACH payment to your bank account."
                  disabled={!ready || props.loading}
                  className={clsx([
                    'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[disabled]:pointer-events-none',
                    'group relative flex cursor-pointer rounded-lg bg-white p-4 shadow-sm focus:outline-none border border-neutral-950/10 data-[hover]:border-neutral-950/20  data-[focus]:border-primary-500 data-[focus]:ring-2 data-[focus]:ring-primary-500 dark:bg-neutral-800 dark:border-white/10 dark:data-[hover]:border-white/20'
                  ])}
                >
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <span className="block text-sm font-medium text-neutral-900 dark:text-white">
                        Connect Bank Account
                      </span>
                      <span className="mt-1 flex items-center text-xs text-neutral-500 dark:text-neutral-400 group-data-[disabled]:text-neutral-600 dark:group-data-[disabled]:text-neutral-300">
                        We will send you an ACH payment to your bank account.
                      </span>
                    </span>
                  </span>
                  <CheckCircleIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-primary-500 [.group:not([data-checked])_&]:invisible"
                  />
                  <span
                    aria-hidden="true"
                    className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-primary-500"
                  />
                </HeadlessCheckbox>
              </div>

              {(validationError?.Field == 'PayoutMethod' || error || props.error) && (
                <ErrorMessage>
                  {validationError?.Message || error?.toString?.() || props.error}
                </ErrorMessage>
              )}
            </Field>
          </FieldGroup>
        </Fieldset>

        <Button
          type="submit"
          color="sky"
          className="mt-4"
          loading={props.loading}
          disabled={props.loading}
        >
          Save Payment Settings
        </Button>
      </form>
    </>
  );
};
