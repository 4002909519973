import * as React from 'react';
import { useLocation, useHistory } from 'react-router';
import {
  linkBIApplicationWithShopify,
  linkMerchantWithCheckfront,
  linkMerchantWithLightspeedRetailRSeries,
  linkMerchantWithShopify
} from '@oysterjs/core/api/merchant';
import { Loadable } from '@oysterjs/ui/Loadable';
import { PageContainer, PageSection } from './components/Page';
import { OysterLogoOld } from '@oysterjs/ui/Logo';
import { ButtonContainer, ButtonLink } from './components/Button';
import { IoArrowForward } from 'react-icons/io5';
import { Spinner } from '@oysterjs/ui/Spinner';

/**
 * This component is the entrypoint to OAuth callback when
 * a user adds the Oyster app to their shopify store. The
 * proposed flow will be:
 *
 * 1. merchant is redirected to /link/shopify?data (this component)
 * 2. if merchant is not logged in:
 *    2a. if merchant has an account:
 *          -> sign in flow with redirect to /link/shopify?data
 *    2b. if merchant does not have account:
 *          -> sign up flow, then log in flow, and redirect to /link/shopify?data
 * 3. if merchant is logged in:
 *      -> show special linking page/animation
 *      -> send request to API endpoint /merchant/link
 *      -> receive integration ID or error if could not link or already linked to another merchant
 * 4. if this integration was already linked to this merchant, this is just a reauthorization
 *      -> redirect back to the embedded app
 *    else:
 *      -> redirect to dashboard page /channels/{ID} and show remaining integration
 *         instructions with link to open app in merchant's shopify store
 */
export const OnboardShopify: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();

  const shop = new URLSearchParams(location.search).get('shop');
  const redirection = `${location.pathname}${location.search}`;

  const biApplicationID = JSON.parse(
    window.localStorage.getItem('oyster-commercial-application-id') || '{}'
  )['BIApplicationID'];

  return (
    <>
      {!biApplicationID && (
        <Loadable request={linkMerchantWithShopify(shop || '', location.search, redirection)}>
          {() => {
            history.replace('/integrations/channels/shopify');
            return <></>;
          }}
        </Loadable>
      )}
      {biApplicationID && (
        <Loadable
          request={linkBIApplicationWithShopify(
            shop || '',
            location.search,
            redirection,
            biApplicationID
          )}
        >
          {() => {
            history.replace(`/app/commercial/${biApplicationID}/connect`);
            return <></>;
          }}
        </Loadable>
      )}
    </>
  );
};

export const OnboardLightspeedRetailRSeries: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();

  const code = new URLSearchParams(location.search).get('code');
  const redirection = `${location.pathname}${location.search}`;

  return (
    <Loadable
      request={linkMerchantWithLightspeedRetailRSeries(code || '', location.search, redirection)}
    >
      {() => {
        history.replace('/integrations/channels/lsretail-rseries');
        return <></>;
      }}
    </Loadable>
  );
};

export const OnboardWooCommerce: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const success = params.get('success') === '1';

  React.useEffect(() => {
    let timeout;
    if (success) {
      timeout = setTimeout(() => history.replace('/integrations/channels/woocommerce'), 2000);
    }

    return () => clearTimeout(timeout);
  }, []);

  return (
    <PageContainer width={600}>
      <PageSection centered>
        <OysterLogoOld scale={1.5} inline />
      </PageSection>
      {success && (
        <PageSection centered>
          <Spinner color="#333333" />
        </PageSection>
      )}
      {!success && (
        <PageSection>
          <h1>Something went wrong</h1>
          <p>There was an error linking your store. Please try again.</p>
          <ButtonContainer>
            <ButtonLink primary icon={<IoArrowForward />} href="/integrations/channels/woocommerce">
              Try Again
            </ButtonLink>
          </ButtonContainer>
        </PageSection>
      )}
    </PageContainer>
  );
};

export const OnboardCheckfront: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();

  const code = new URLSearchParams(location.search).get('code');
  const redirection = `${location.pathname}${location.search}`;

  return (
    <Loadable request={linkMerchantWithCheckfront(code || '', location.search, redirection)}>
      {() => {
        history.replace('/integrations/channels/checkfront');
        return <></>;
      }}
    </Loadable>
  );
};
